import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Users,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
} from "./pages";
import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";
import Countries from "./pages/NewPages/Countries";
import CreateCountry from "./pages/NewPages/CreateCountry";
import EditUser from "./pages/NewPages/EditUser";
import Login from "./pages/Login/Login";
import Days from "./pages/NewPages/Days";
import CreateDay from "./pages/NewPages/CreateDay";
import Leads from "./pages/NewPages/Leads";
import Trips from "./pages/NewPages/Trips";
import CreateTrip from "./pages/NewPages/CreateTrip";
import LocalExperts from "./pages/NewPages/LocalExperts";
import Currency from "./pages/NewPages/Currency";
import CreateCurrency from "./pages/NewPages/CreateCurrency";
import CreateExpert from "./pages/NewPages/CreateExpert";
import Language from "./pages/NewPages/Language";
import CreateLanguage from "./pages/NewPages/CreateLanguage";

import CarRentalPrices from "./pages/NewPages/CarRentalPrices";
import CreateCarRent from "./pages/NewPages/CreateCarRent";
import EditLead from "./pages/NewPages/EditLead";
import HotelsPrices from "./pages/NewPages/HotelsPrices";
import CreateHotels from "./pages/NewPages/CreateHotels";
import Addons from "./pages/NewPages/Addons";
import CreateAddons from "./pages/NewPages/CreateAddons";

import Purchases from "./pages/NewPages/Purchases";
import Attractions from "./pages/NewPages/Attractions";
import CreateAttraction from "./pages/NewPages/CreateAttraction";
import POI from "./pages/NewPages/POI";
import CreatePOI from "./pages/NewPages/CreatePOI";
import GPX from "./pages/NewPages/GPX";
import Changelog from "./pages/NewPages/Changelog";
import ChangelogValues from "./pages/NewPages/ChangelogValues";
import CreateGPX from "./pages/NewPages/CreateGPX";
import SelfGuided from "./pages/NewPages/SelfGuided";
import CreateSelfGuided from "./pages/NewPages/CreateSelfGuided";
import EmailTemplateView from "./pages/NewPages/EmailTemplateView";

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  const [loginData, setLoginData] = useState([]);

useEffect(() => {
  const tempLoginData = JSON.parse(localStorage.getItem('loginData'));
  if (tempLoginData) {
    setLoginData(tempLoginData);
  }
}, []);


  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
      <Routes>
      <Route path="/templateView" element={<EmailTemplateView />} />
      </Routes>
      
    {loginData && loginData.email && window.location.pathname != '/templateView'  ?
    <div className="flex relative dark:bg-main-dark-bg">
    <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
      <TooltipComponent content="Settings" position="Top">
        <button
          type="button"
          onClick={() => setThemeSettings(true)}
          style={{ background: currentColor, borderRadius: "50%" }}
          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
        >
          <FiSettings />
        </button>
      </TooltipComponent>
    </div>
    {activeMenu ? (
      <div className="fixed sidebar dark:bg-secondary-dark-bg bg-white" style={{width:'250px'}}>
        <Sidebar />
      </div>
    ) : (
      <div className="dark:bg-secondary-dark-bg" style={{width:'65px'}}>
        <Sidebar />
      </div>
    )}
    <div
      className={
        activeMenu
          ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen "
          : "bg-main-bg dark:bg-main-dark-bg  min-h-screen flex-2 "
      }
      style={activeMenu?{width:'calc(100vw - 255px)',marginLeft:'250px'}:{width:'calc(100vw - 65px)',marginLeft:'0px'}}
    >
      <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
        <Navbar />
      </div>
      <div>
        {themeSettings && <ThemeSettings />}

        <Routes>
          {/* dashboard  */}
          <Route path="/" element={<Users />} />
          {/* <Route path="/ecommerce" element={<Ecommerce />} /> */}

          {/* pages  */}
          {/* <Route path="/orders" element={<Orders />} />
          <Route path="/employees" element={<Employees />} /> */}
          <Route path="/users" element={<Users />} />
          <Route path="/countries" element={<Countries />} />
          <Route path="/createCountry" element={<CreateCountry />} />
          <Route path="/editCountry" element={<CreateCountry />} />
          <Route path="/editUser" element={<EditUser />} />

          <Route path="/trips" element={<Trips />} />
          <Route path="/createTrip" element={<CreateTrip />} />
          <Route path="/editTrip" element={<CreateTrip />} />

          <Route path="/days" element={<Days />} />
          <Route path="/createDay" element={<CreateDay />} />
          <Route path="/editDay" element={<CreateDay />} />

          <Route path="/leads" element={<Leads />} />
          <Route path="/editLead" element={<EditLead />} />

          <Route path="/localExperts" element={<LocalExperts />} />
          <Route path="/createExpert" element={<CreateExpert />} />
          <Route path="/editExpert" element={<CreateExpert />} />

          <Route path="/currency" element={<Currency />} />
          <Route path="/createCurrency" element={<CreateCurrency />} />
          <Route path="/editCurrency" element={<CreateCurrency />} />

          <Route path="/addons" element={<Addons />} />
          <Route path="/createAddons" element={<CreateAddons />} />

          <Route path="/language" element={<Language />} />
          <Route path="/createLanguage" element={<CreateLanguage />} />
          <Route path="/editLanguage" element={<CreateLanguage />} />



          <Route path="/Purchases" element={<Purchases />} />
          <Route path="/Changelog" element={<Changelog />} />
          <Route path="/ChangelogValues" element={<ChangelogValues />} />

          <Route path="/CarRentalPrices" element={<CarRentalPrices />} />
          <Route path="/createCarRent" element={<CreateCarRent />} />
          <Route path="/editCarRent" element={<CreateCarRent />} />

          <Route path="/HotelPriceList" element={<HotelsPrices />} />
          <Route path="/createHotel" element={<CreateHotels />} />
          <Route path="/editHotel" element={<CreateHotels />} />

          <Route path="/attractions" element={<Attractions />} />
          <Route path="/createAttraction" element={<CreateAttraction />} />
          <Route path="/editAttraction" element={<CreateAttraction />} />

          <Route path="/poi" element={<POI />} />
          <Route path="/createPoi" element={<CreatePOI />} />
          <Route path="/editPoi" element={<CreatePOI />} />

          <Route path="/gpx" element={<GPX />} />
          <Route path="/createGpx" element={<CreateGPX />} />
          <Route path="/editGpx" element={<CreateGPX />} />

          <Route path="/selfGuided" element={<SelfGuided />} />
          <Route path="/createSelfGuided" element={<CreateSelfGuided />} />
          <Route path="/editSelfGuided" element={<CreateSelfGuided />} />

         


          {/* apps  */}
          {/* <Route path="/kanban" element={<Kanban />} />
          <Route path="/editor" element={<Editor />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/color-picker" element={<ColorPicker />} /> */}

          {/* charts  */}
          {/* <Route path="/line" element={<Line />} />
          <Route path="/area" element={<Area />} />
          <Route path="/bar" element={<Bar />} />
          <Route path="/pie" element={<Pie />} />
          <Route path="/financial" element={<Financial />} />
          <Route path="/color-mapping" element={<ColorMapping />} />
          <Route path="/pyramid" element={<Pyramid />} />
          <Route path="/stacked" element={<Stacked />} /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  </div>
:
<Routes>
<Route path="/" element={<Login />} />
</Routes>

}

<ToastContainer/>
      </BrowserRouter>
    </div>
  );
};

export default App;
