import React, { useEffect, useState } from "react";
import AppGuideSgpt from '../../data/AppGuideSgpt.png';
import IndieCircleLogo from '../../data/Indie_circle_logo.png';
import SgptGreenCheck from '../../data/sgptGreenCheck.png';
import './EmailTemplateView.css';


const EmailTemplateView = () => {

  const [sharedData, setSharedData] = useState(null);

  const [mobileView, setMobileView] = useState(
    window.innerWidth <= 768
  );

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem('sharedData');
    if (storedData) {
      // Parse the data if it exists
      const parsedData = JSON.parse(storedData);
      setSharedData(parsedData);
      // Optional: Clear the data from localStorage
      localStorage.removeItem('sharedData');
    }
  }, []);

  return (
    <div className="mainTemplateBgImg">
      <div style={{ width: mobileView ? '95%' : '75%', marginTop: '2rem', display: 'block', margin: '0 auto' }}>
        <div style={{ background: 'white', paddingBottom: mobileView ? 0 : '1rem', height: '100%' }}>
          <div style={{ display: 'table', width: '100%', padding: mobileView ? '20px 50px 10px 0px' : '20px 50px 10px 15px' }}>
            <div style={{ display: 'table-row', paddingTop: '1rem', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'table-cell', marginRight: 10 }}>
                <img src={IndieCircleLogo} style={{ width: 100, height: 100, marginTop: -15, marginLeft: 16 }} />
              </div>
              <div style={{ display: "table-cell", textAlign: 'left', marginTop: 10, marginRight: 20, fontSize: 13, fontWeight: 400, fontFamily: 'inter' }}>
              </div>
            </div>
          </div>

          <div style={{ textAlign: 'center', width: '100%', padding: mobileView ? '20px 15px 20px 15px' : '50px 50px 20px 40px' }}>
            {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?
              <div style={{ fontStyle: 'normal', fontWeight: 600, fontSize: 20, textAlign: 'left', color: '#00141A', fontFamily: 'interSemiBold' }}>
                Hi {sharedData && sharedData.formFirstName && sharedData.formFirstName} {sharedData && sharedData.formLastName && sharedData.formLastName},
              </div>
              :
              <div style={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 20,
                textAlign: 'right',
                color: '#00141A',
                fontFamily: 'interSemiBold',
                direction: 'rtl'
              }}>
                שלום {sharedData && sharedData.formFirstName && sharedData.formFirstName} {sharedData && sharedData.formLastName && sharedData.formLastName},
              </div>
            }
            {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?
              <p style={{
                fontStyle: 'normal', fontSize: 20,
                textAlign: 'left', color: '#00141A', fontFamily: 'inter'
              }}> This is your personalized trip. {sharedData && sharedData.trip && sharedData.trip.name ? sharedData.trip.name : ''}. Your trip includes:
              </p> :
              <p style={{
                fontStyle: 'normal', fontSize: 20,
                textAlign: 'right', color: '#00141A', fontFamily: 'inter',
                direction: 'rtl'
              }}> 
             זהו הטיול המותאם אישית שלך. {sharedData && sharedData.trip && sharedData.trip.name ? sharedData.trip.name : ''}. הטיול שלך כולל:
              </p>
            }
          </div>
        </div>
        {!mobileView ?
          sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?
            <>
              <div style={{ background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                <div style={{ width: '50%', height: 250, background: '#fff' }}>
                  <img style={{ width: '100%', height: 250, objectFit: 'cover' }} src={sharedData && sharedData.trip && sharedData.trip.image ? sharedData.trip.image : ''} alt="" />
                </div>
                <div style={{ width: '25%', height: 250, background: '#fff' }}>
                  <img style={{ width: '100%', height: 250, objectFit: 'cover' }} src={AppGuideSgpt} alt="" />
                </div>
                <div style={{ width: '25%', height: 250, background: '#fff' }}>
                  <img style={{ width: '100%', height: 250, objectFit: 'cover' }} src={sharedData && sharedData.localExpertImage ? sharedData.localExpertImage : ''} alt="" />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, width: '100%', marginBottom: 20 }}>

                <div style={{ width: '50%', height: 150, background: '#fff', padding: '25px 22px' }}>
                  <img src={SgptGreenCheck} style={{ width: 30, height: 30, position: 'absolute', marginTop: -40, marginLeft: 0 }} alt="" />
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5 }}>
                    {sharedData && sharedData.trip && sharedData.trip.days && sharedData.trip.days.length > 0 ? sharedData.trip.days.length : '0'}-days personalized itinerary
                  </div>
                  <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter' }}>
                    Once you planned your trip, your personalized itinerary is ready.
                  </div>
                </div>


                <div style={{ width: '25%', height: 150, background: '#fff', padding: '25px 22px' }}>
                  <img src={SgptGreenCheck} style={{ width: 30, height: 30, position: 'absolute', marginTop: -40, marginLeft: 0 }} alt="" />
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5 }}>
                    Indie App Guide
                  </div>
                  <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter' }}>
                    Navigate your entire Itinerary on the go and receive relevant tips along your route.
                  </div>
                </div>


                <div style={{ width: '25%', height: 150, background: '#fff', padding: '25px 22px' }}>
                  <img src={SgptGreenCheck} style={{ width: 30, height: 30, position: 'absolute', marginTop: -40, marginLeft: 0 }} alt="" />
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5 }}>
                    Local Expert on the Ground for You
                  </div>
                  <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter' }}>
                    Will be there for you advising, arranging, helping, suggesting.
                  </div>
                </div>

              </div>

            </>
            :
            <>
              <div style={{ background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                <div style={{ width: '25%', height: 250, background: '#fff' }}>
                  <img style={{ width: '100%', height: 250, objectFit: 'cover' }} src={sharedData && sharedData.localExpertImage ? sharedData.localExpertImage : ''} alt="" />
                </div>
                <div style={{ width: '25%', height: 250, background: '#fff' }}>
                  <img style={{ width: '100%', height: 250, objectFit: 'cover' }} src={AppGuideSgpt} alt="" />
                </div>
                <div style={{ width: '50%', height: 250, background: '#fff' }}>
                  <img style={{ width: '100%', height: 250, objectFit: 'cover' }} src={sharedData && sharedData.trip && sharedData.trip.image ? sharedData.trip.image : ''} alt="" />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, width: '100%', marginBottom: 20 }}>

                <div style={{ width: '25%', height: 150, background: '#fff', padding: '25px 22px' }}>
                  <img src={SgptGreenCheck} style={{ width: 30, height: 30, position: 'absolute', marginTop: -40, marginLeft: 0 }} alt="" />
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5, direction: 'rtl' }}>
                    תמיכה של המשרד המקומי
                  </div>
                </div>

                <div style={{ width: '25%', height: 150, background: '#fff', padding: '25px 22px' }}>
                  <img src={SgptGreenCheck} style={{ width: 30, height: 30, position: 'absolute', marginTop: -40, marginLeft: 0 }} alt="" />
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5, direction: 'rtl' }}>
                  ערכת המדריך הדיגיטלי עם תוכנת ניווט שטח ייעודית
                  </div>
                </div>

                <div style={{ width: '50%', height: 150, background: '#fff', padding: '25px 22px' }}>
                  <img src={SgptGreenCheck} style={{ width: 30, height: 30, position: 'absolute', marginTop: -40, marginLeft: 0 }} alt="" />
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5, direction: 'rtl' }}>
                  חבילת טיול של {sharedData && sharedData.trip && sharedData.trip.days && sharedData.trip.days.length > 0 ? sharedData.trip.days.length : '0'} ימים
                  </div>
                  <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter', direction: 'rtl' }}>
                  לאחר שתכננת את הטיול שלך, המסלול האישי שלך מוכן.
                  </div>
                </div>
              </div>

            </>
          :
          <div style={{ background: '#fff', marginBottom: 15 }}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, width: '100%', marginBottom: 0, flexDirection: 'column' }}>
              <div style={{ width: '100%', background: '#fff', padding: '15px', display: 'flex', gap: 15, paddingTop: 0 }}>
                <img src={SgptGreenCheck} style={{ width: 30, height: 30, }} alt="" />
                {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?
                  <div>
                    <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5 }}>
                      {sharedData && sharedData.trip && sharedData.trip.days && sharedData.trip.days.length > 0 ? sharedData.trip.days.length : '0'}-days personalized itinerary
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter' }}>
                      Once you planned your trip, your personalized itinerary is ready.
                    </div>
                  </div> :
                  <div>
                    <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5, direction: 'rtl' }}>
                      חבילת טיול מותאמת אישית
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter', direction: 'rtl' }}>
                      לאחר שתתכננת את הטיול שלך, התוכנית האישית שלך מוכנה.
                    </div>
                  </div>
                }
              </div>
              <div style={{ width: '100%', background: '#fff', padding: '15px', display: 'flex', gap: 15, paddingTop: 0 }}>
                <img src={SgptGreenCheck} style={{ width: 30, height: 30, }} alt="" />
                {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ? <div>
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5 }}>
                    Indie App Guide
                  </div>
                  <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter' }}>
                    Navigate your entire Itinerary on the go and receive relevant tips along your route.
                  </div>
                </div> :
                  <div>
                    <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5, direction: 'rtl' }}>
                      אפליקצית אינדי לניווט והדרכה
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter', direction: 'rtl' }}>
                      נווט בכל התוכנית שלך בדרך וקבל טיפים רלוונטיים לאורך המסלול שלך.
                    </div>
                  </div>
                }
              </div>
              <div style={{ width: '100%', background: '#fff', padding: '15px', display: 'flex', gap: 15, paddingTop: 0 }}>
                <img src={SgptGreenCheck} style={{ width: 30, height: 30, }} alt="" />
                {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ? <div>
                  <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5 }}>
                    Local Expert on the Ground for You
                  </div>
                  <div style={{ fontSize: 14, fontWeight: 400, fontFamily: 'inter' }}>
                    Will be there for you advising, arranging, helping, suggesting.
                  </div>
                </div> :
                  <div>
                    <div style={{ fontSize: 14, fontWeight: 600, fontFamily: 'interSemiBold', marginBottom: 5, direction: 'rtl' }}>
                      ליווי טלפוני צמוד של מדריך שטח ביעד ומהארץ
                    </div>
                  </div>
                }
              </div>
            </div>
            <div style={{ background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0 }}>
              <div style={{ width: '50%', height: 70, background: '#fff' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={sharedData && sharedData.trip && sharedData.trip.image ? sharedData.trip.image : ''} alt="" />
              </div>
              <div style={{ width: '25%', height: 70, background: '#fff' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={AppGuideSgpt} alt="" />
              </div>
              <div style={{ width: '25%', height: 70, background: '#fff' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={sharedData && sharedData.localExpertImage ? sharedData.localExpertImage : ''} alt="" />
              </div>

            </div>
          </div>
        }
        {/* <div  style={{width: '100%', marginTop: '2rem', display: 'block', margin: '0 auto',}}>
        <div  style={{background: 'white', paddingBottom: '1rem',
        height: '100%'}}>
            <div style={{position: 'relative', width: '100%', maxWidth: '80%', margin: '0 auto', textAlign: 'center', paddingBottom: '3rem',}}>
            <img style={{width: '100%', height: 300}} src={sharedData && sharedData.trip && sharedData.trip.image ? sharedData.trip.image : ''} alt="" />
            <img style={{width: '100%', height: 300,marginTop:5}} src={TemplateMapImg} alt="" />
            
          </div>
      </div>
      </div> */}

        {(sharedData && sharedData.overnightPreNight && sharedData.overnightPreHotelSiteLink) && <div style={{
          width: '100%', height: 50, margin: '0 auto', marginTop: 10, textAlign: 'start',
          background: '#FFF', display: 'flex', alignItems: 'center',
          flexDirection: sharedData && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'row-reverse' : 'row'
        }}>

          {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?

            <p style={{ marginTop: 0, fontSize: 17, paddingLeft: mobileView ? 15 : 35, fontWeight: '700' }}>
              Pre-overnight: {sharedData.overnightPreNight}, <a href={sharedData.overnightPreHotelSiteLink} style={{ color: "blue" }} target="_blank">{sharedData.selectedOptionPre}</a>
            </p>
            :
            <p style={{ marginTop: 0, fontSize: 17, paddingRight: mobileView ? 15 : 35, fontWeight: '700', direction: 'rtl' }}>
              לינה לפני הטיול: {sharedData.overnightPreNight}, <a href={sharedData.overnightPreHotelSiteLink} style={{ color: "blue" }} target="_blank">{sharedData.selectedOptionPre}</a>
            </p>}
        </div>}

        {sharedData && sharedData.trip && sharedData.trip.days && sharedData.trip.days.length > 0 && sharedData.trip.days.map((item, index) => (
          <div style={{ paddingBottom: '1rem' }}>


            <div style={{ width: '100%', height: 50, margin: '0 auto', marginTop: 10, textAlign: 'start', background: '#A7C8EC', display: 'flex', alignItems: 'center', flexDirection: sharedData && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'row-reverse' : 'row' }}>
              {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?
                <h4 style={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 20,
                  lineHeight: '30px',
                  color: '#FFFFFF',
                  paddingLeft: mobileView ? 15 : 35,
                  margin: 0,
                  fontFamily: 'poppinsSemiBold'
                }}>Day {index + 1} - {item.name}</h4> :
                <h4 style={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 20,
                  lineHeight: '30px',
                  color: '#FFFFFF',
                  margin: 0,
                  paddingRight: 30,
                  fontFamily: 'poppinsSemiBold'
                }}>
                  יום {index + 1} - {item.name} 
                </h4>
              }
            </div>


            <div style={{
              width: '100%',
              background: 'white',
              paddingTop: 20
            }}>

              {/* <div style={{
                marginTop: 0, marginBottom: 10, fontSize: 17,
                paddingLeft: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 0 : mobileView ? 15 : 35,
                paddingRight: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? mobileView ? 15 : 35 : 0,
                fontWeight: '700', fontFamily: 'interBold',
                textAlign: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left',
                direction: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr'
              }}>{item.name}</div> */}
              <p style={{
                marginTop: 0, marginBottom: 10, fontSize: 17, paddingLeft: mobileView ? 15 : 35, paddingRight: '4rem', fontWeight: '400', fontFamily: 'inter',
                textAlign: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left',
                direction: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr'
              }}>{item.description}</p>

              {item.gpxs_pois_sorted && item.gpxs_pois_sorted.length > 0 &&
                <ul style={{ 
                  marginTop: 0, 
                  marginBottom: 10, 
                  fontSize: 17, 
                  paddingLeft: mobileView ? '3rem' : '5rem', 
                  paddingRight: mobileView ? '3rem' : '4rem', 
                  listStyleType: 'disc', 
                  fontWeight: '400', 
                  fontFamily: 'inter', 
                  lineHeight: '30px'
                }}>
                  {item.gpxs_pois_sorted.map((poiItem, index) => (
                    poiItem &&
                    <div key={index} style={{
                      marginBottom: 8,
                      textAlign: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left',
                      direction: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr'
                    }}>
                      {poiItem.name} - {poiItem.description ? poiItem.description : ''}
                    </div>
                  ))}
                </ul>
              }

              { item && item?.overnight && 
              // item && item.hotels && item.hotels.id &&
                <>
                  {sharedData & sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ?
                    <div style={{ marginTop: 0, marginBottom: 10, fontSize: 17, paddingRight: mobileView ? 15 : 35, fontWeight: '700', fontFamily: 'interBold', direction: 'rtl' }}>לינה</div>
                    : <div style={{ marginTop: 0, marginBottom: 10, fontSize: 17, paddingLeft: mobileView ? 15 : 35, fontWeight: '700', fontFamily: 'interBold' }}>Overnight</div>}
                  {sharedData && sharedData?.languageSelected && sharedData?.languageSelected.toLowerCase().includes("hebrew") ?
                    // <div style={{ marginTop: 0, marginBottom: 10, fontSize: 17, paddingRight: mobileView ? 15 : 35, fontWeight: '400', fontFamily: 'inter', direction: 'rtl' }}>{item.hotels.overnight_city}, <u style={{ cursor: 'pointer' }} onClick={() => { window.open(item.hotels.site_link, '_blank'); }}>{item.hotels.hotel_name} fjkoedfjoiergjk</u></div>
                    // : <div style={{ marginTop: 0, marginBottom: 10, fontSize: 17, paddingLeft: mobileView ? 15 : 35, fontWeight: '400', fontFamily: 'inter' }}>{item.hotels.overnight_city}, <u style={{ cursor: 'pointer' }} onClick={() => { window.open(item.hotels.site_link, '_blank'); }}>{item.hotels.hotel_name} jknsdfhoegerl</u></div>
                    <div style={{ marginTop: 0, marginBottom: 10, fontSize: 17, paddingRight: mobileView ? 15 : 35, fontWeight: '400', fontFamily: 'inter', direction: 'rtl' }}>{sharedData.hotelsData.filter(hotel => hotel.dayIndex === index)[0] ? sharedData.hotelsData.filter(hotel => hotel.dayIndex === index)[0].data.hotel_name : ''}, {item.overnight}</div>
                    : <div style={{ marginTop: 0, marginBottom: 10, fontSize: 17, paddingLeft: mobileView ? 15 : 35, fontWeight: '400', fontFamily: 'inter' }}>{item.overnight}, {sharedData.hotelsData.filter(hotel => hotel.dayIndex === index)[0] ? sharedData.hotelsData.filter(hotel => hotel.dayIndex === index)[0].data.hotel_name : ''}</div>
                    }
                </>
              }

              <div style={{
                width: '100%',
                maxWidth: '100%', marginTop: mobileView ? 20 : 40,
                textAlign: 'center', padding: mobileView ? '0px 15px' : '0px 65px'
              }}>
                <img style={{ width: mobileView ? '100%' : '90%', height: mobileView ? 200 : 360, objectFit: 'cover', margin: '0 auto' }} src={item.imageURL} alt="" />
              </div>
              <div style={{ width: '100%', marginBottom: 10, height: mobileView ? 40 : 80 }}>
              </div>
            </div>
          </div>
        ))}

        {(sharedData && sharedData.overnightPostNight && sharedData.overnightPostHotelSiteLink) && <div style={{
          width: '100%', height: 50, margin: '0 auto', marginBottom: 10, textAlign: 'start',
          background: '#FFF', display: 'flex', alignItems: 'center',
          flexDirection: sharedData && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'row-reverse' : 'row'
        }}>

          {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?

            <p style={{ marginTop: 0, fontSize: 17, paddingLeft: mobileView ? 15 : 35, fontWeight: '700' }}>
              Post-overnight: {sharedData.overnightPostNight}, <a href={sharedData.overnightPostHotelSiteLink} style={{ color: "blue" }} target="_blank">{sharedData.selectedOptionPost}</a>
            </p>
            :
            <p style={{ marginTop: 0, fontSize: 17, paddingRight: mobileView ? 15 : 35, fontWeight: '700', direction: 'rtl' }}>
              לינה אחרי הטיול: {sharedData.overnightPostNight}, <a href={sharedData.overnightPostHotelSiteLink} style={{ color: "blue" }} target="_blank">{sharedData.selectedOptionPost}</a>
            </p>}
        </div>}

        {sharedData && sharedData.dataAddon && (
  <div style={{
    width: '100%',
    height: 'auto',
    margin: '0 auto',
    marginBottom: 10,
    textAlign: 'start',
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    flexDirection: sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'row-reverse' : 'row'
  }}>
    <div style={{ width: '100%' }}>
      <p style={{
        marginTop: 0,
        fontSize: 20,
        paddingLeft: mobileView ? 15 : 35,
        fontWeight: '700',
        direction: sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr',
        textAlign: sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left'
      }}>
        {sharedData.dataAddon.title}
      </p>

      {sharedData.dataAddon.texts && sharedData.dataAddon.texts.map((textObj, index) => (
      <ul style={{
        marginTop: 0,
        paddingLeft: mobileView ? 15 : 35,
        paddingRight: mobileView ? 15 : 35,
        direction: sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr',
        textAlign: sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left'
      }}>
      <li key={index} style={{
            fontSize: 17,
            fontWeight: '700'
          }}>
            - {textObj.text}
          </li>
      </ul>
      ))}
    </div>
  </div>
)}



        


        <div style={{ marginBottom: '10rem' }}>
          <div style={{
            width: '100%',
            marginTop: '2rem',
            margin: '0 auto',
            background: 'white',
            paddingBottom: 25,
          }}>
            <div style={{
              paddingLeft: 35, paddingBottom: '1rem',
              paddingTop: '1rem', padding: mobileView ? '20px 15px' : '69px 77px 10px 35px'
            }}>
              {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ?
                <h1 style={{ color: '#613EEA', fontSize: 28, fontWeight: '600', fontFamily: 'poppinsSemiBold' }}>Price per person: {sharedData && sharedData.currency ? sharedData.currency.symbol : ""} {sharedData && sharedData.price ? sharedData.price : ""}</h1>
                : <h1 style={{ color: '#613EEA', fontSize: 28, fontWeight: '600', fontFamily: 'poppinsSemiBold', direction: 'rtl' }}>מחיר לפי אדם: {sharedData && sharedData.currency ? sharedData.currency.symbol : ""} {sharedData && sharedData.price ? sharedData.price : ""}</h1>
              }
              {/* <div style={{ marginTop: 15, marginBottom: 10, fontSize: 14, lineHeight: '30px' }}>
                {sharedData && sharedData.priceDescription ?
                  <div style={{
                    textAlign: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left',
                    direction: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr'
                  }} dangerouslySetInnerHTML={{
                    __html: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? sharedData.priceDescriptionHebrew : sharedData.priceDescription
                  }}>
                  </div>

                  : ""}
              </div> */}
            </div>
            <div style={{ width: '94%', marginBottom: '1rem', height: 2, backgroundColor: '#613EEA', margin: '0 auto' }}>
            </div>
            <div style={{ width: '80%', margin: '10px auto', fontSize: 14, fontWeight: '400', fontFamily: 'inter', paddingTop: 12, lineHeight: '30px' }}>
              {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ? <p style={{ fontSize: 18 }}>Price includes</p> :
                <p style={{ fontSize: 18, direction: 'rtl' }}>המחיר כולל</p>
              }
              <div style={{ marginTop: 0, marginBottom: 10, fontSize: 14 }}>
                {sharedData && sharedData.priceIncludes ?
                  <div style={{
                    textAlign: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left',
                    direction: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr'
                  }} dangerouslySetInnerHTML={{ __html: sharedData.languageSelected.toLowerCase().includes("hebrew") ? sharedData.priceIncludesHebrew : sharedData.priceIncludes }}>
                  </div>
                  : ""}
              </div>
            </div>
            <div style={{ width: '80%', margin: '10px auto', fontSize: 14, fontWeight: '400', fontFamily: 'inter', paddingTop: 12, lineHeight: '30px' }}>
              {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ? <p style={{ fontSize: 18 }}>Price excludes</p> :
               <p style={{ fontSize: 18, direction: 'rtl' }}>המחיר אינו כולל</p>}
              <div style={{ marginTop: 0, marginBottom: 10, fontSize: 14 }}>
                {sharedData && sharedData.excluded ?
                  <div style={{
                    textAlign: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left',
                    direction: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr'
                  }} dangerouslySetInnerHTML={{ __html: sharedData.languageSelected.toLowerCase().includes("hebrew") ? sharedData.excludedHebrew : sharedData.excluded }}>
                  </div>
                  : ""}
              </div>
            </div>
            {/* {sharedData && sharedData.confirmPolicy &&
              <div style={{ width: '80%', margin: '10px auto', fontSize: 14, fontWeight: '400', fontFamily: 'inter', paddingTop: 12, paddingBottom: 12, lineHeight: '30px' }}>
                {sharedData && !sharedData.languageSelected.toLowerCase().includes("hebrew") ? <p style={{ fontSize: 18 }}>Cancellation Policy</p> : <p style={{ fontSize: 18, direction: 'rtl' }}>מדיניות ביטול</p>}
                <div style={{ marginTop: 0, marginBottom: 10, fontSize: 14 }}>
                  {sharedData && sharedData.confirmPolicy ?
                    <div style={{
                      textAlign: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'right' : 'left',
                      direction: sharedData && sharedData.languageSelected && sharedData.languageSelected.toLowerCase().includes("hebrew") ? 'rtl' : 'ltr'
                    }} dangerouslySetInnerHTML={{ __html: sharedData.languageSelected.toLowerCase().includes("hebrew") ? sharedData.confirmPolicyHebrew : sharedData.confirmPolicy }}>
                    </div>
                    : ""}
                </div>
              </div>
            } */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateView;
