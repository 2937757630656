import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { gpx } from "@tmcw/togeojson";
import $ from 'jquery';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleFileUpload from 'react-simple-file-upload';
import { BASE_URL, get, patch, post } from "../../Services/Calls";
import { mapboxglAccessToken, notify, SimpleFileUploadAPIKey } from '../../Services/Utils';
import { Header } from "../../components";
import BlueCheck from '../../data/blueCheck.png';
import DeleteIcon from '../../data/deleteIcon.png';
import DragIcon from '../../data/dragIcon.png';
import EditIcon from '../../data/edit.png';
import DropDownArrow from '../../data/rightArrow.png';
import "../../pages/NewPages/Map/MapBox.css";
import CreateWayPoints from "./CreateWayPoints";
import './NewMain.css';

mapboxgl.accessToken = mapboxglAccessToken;


const CreateGPX = () => {

    const navigate = useNavigate()
    const location = useLocation();

    const gridRef = useRef(null);
    const toolbarOptions = ['Delete', 'Search'];
    const selectedRecordsRef = useRef(null)
    const [selectedRecords, setSelectedRecords] = useState([])
    const [poiIdArray, setPoiIdArray] = useState([])

    const [gpxName, setGpxName] = useState('');
    const [imageName, setImageName] = useState('');
    const [gpxFileName, setGPXFileName] = useState('');
    const [description, setDescription] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [fromStatus, setFromStatus] = useState('');
    const [gpxId, setGpxId] = useState('');
    const [showImagePopUp, setShowImagePopUp] = useState(false);
    const [showGPXFilePopUP, setShowGPXFilePopUP] = useState(false);
    const [showAddWayPointPopUp, setShowAddWayPointPopUp] = useState(false);

    const [gpxStartPoint, setGpxStartPoint] = useState('');
    const [gpxEndPoint, setGpxEndPoint] = useState('');
    const [showCountrySelect, setShowCountrySelect] = useState(false);
    const [direction, setDirection] = useState("ltr");  // Initial direction is left-to-right

    const [wayPointData, setWayPointData] = useState([]);

    const [poiData, setPoiData] = useState([]);
    const [friendlyId, setFriendlyId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [country, setCountry] = useState({});

    const [type, setType] = useState(0);
    const [level, setLevel] = useState(0);
    const [personalized, setPersonalized] = useState(true);

    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const [distance, setDistance] = useState('');
    const [duration, setDuration] = useState('');
    const [ascent, setAscent] = useState('');
    const [showTrailTypeSelect, setShowTrailTypeSelect] = useState(false);
    const [trailType, setTrailType] = useState({});
    const [trailTypeArray, setTrailTypeArray] = useState([
        {
            id: 1,
            name: 'HIKING',
            type: 1,
        },
        {
            id: 2,
            name: 'BICYCLE',
            type: 2,
        },
        {
            id: 3,
            name: '4X4',
            type: 3,
        },
        {
            id: 4,
            name: 'BIKE',
            type: 4,
        },
    ]);

    const [wayPointItem, setWayPointItem] = useState({});
    const [wayPointIndex, setWayPointIndex] = useState(0);

    const [map, setMap] = useState(null);
    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
    const [mapMode, setMapMode] = useState('1');

    const mapRef = useRef(null);
    const mapRef2 = useRef(null);
    const mapRef4 = useRef(null);
    const markerRef = useRef(null);
    const marker2Ref = useRef(null);
    const marker4Ref = useRef(null);

    const toggleDirection = () => {
        setDirection((prevDirection) => (prevDirection === "ltr" ? "rtl" : "ltr"));
    };

    useEffect(() => {
        (async () => {
            await getCountries();
        })();
    }, []);

    const getPoi = async () => {
        try {
            const response = await get(BASE_URL + '/pois')

            if (response && response.results && response.results.length > 0) {
                setPoiData(response.results);
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    const getPoiEdit = async (tempPoiIdArray) => {
        try {
            const response = await get(BASE_URL + '/pois');

            if (response && response.results && response.results.length > 0) {
                setPoiData(response.results)

                setTimeout(() => {
                    let rowIndexes = []
                    if (tempPoiIdArray && tempPoiIdArray.length > 0) {
                        let tempPoiData = response.results
                        for (let j = 0; j < tempPoiIdArray.length; j++) {
                            for (let i = 0; i < tempPoiData.length; i++) {
                                if (tempPoiIdArray[j] == tempPoiData[i].id) {
                                    rowIndexes.push(i);
                                }
                            }
                        }
                    }
                    setPoiIdArray(tempPoiIdArray)
                    if (gridRef && gridRef.current) {
                        const selectedRowIndices = rowIndexes; // Specify the indices of rows you want to be initially selected
                        gridRef.current.selectRows(selectedRowIndices);
                    }
                }, 1000);
            }
        }
        catch (ex) {
            alert(ex);
        }

    };

    const getCountries = async () => {
        try {
            const response = await get(BASE_URL + '/countries?order=ASC')
            if (response && response.length > 0) {
                setCountryData(response)
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    useEffect(() => {
        // Create a map instance
        mapRef.current = new mapboxgl.Map({
            container: 'map', // Specify the container ID
            style: mapStyle, // Specify the map style
            center: [32.00, 35.00], // Specify the starting center
            zoom: 10, // Specify the starting zoom
        });

        mapRef.current.addControl(new mapboxgl.NavigationControl());

        mapRef.current.on('load', () => {
            setMap(mapRef.current);
        });

        mapRef.current.on('style.load', function () {
            mapRef.current.on('click', function (e) {
                var coordinates = e.lngLat;
                new mapboxgl.Popup()
                if (markerRef.current != null) {
                    markerRef.current.remove()
                }
                markerRef.current = new mapboxgl.Marker()
                    .setLngLat(coordinates)
                    .addTo(mapRef.current);

                setGpxStartPoint(coordinates.lat + "," + coordinates.lng);
                mapRef.current.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 10 });
            });
        });

        // Clean up the map instance when the component unmounts
        return () => mapRef.current.remove();
    }, [mapStyle, mapMode]); // The empty dependency array ensures this useEffect runs only once

    useEffect(() => {
        // Create a map instance
        mapRef2.current = new mapboxgl.Map({
            container: 'map2', // Specify the container ID
            style: mapStyle, // Specify the map style
            center: [32.00, 35.00], // Specify the starting center
            zoom: 10, // Specify the starting zoom
        });

        mapRef2.current.addControl(new mapboxgl.NavigationControl());
        mapRef2.current.on('load', () => {
            setMap(mapRef2.current);
        });

        mapRef2.current.on('style.load', function () {
            mapRef2.current.on('click', function (e) {
                var coordinates = e.lngLat;
                new mapboxgl.Popup()
                if (marker2Ref.current != null) {
                    marker2Ref.current.remove()
                }
                marker2Ref.current = new mapboxgl.Marker()
                    .setLngLat(coordinates)
                    // .setHTML('' + coordinates)
                    .addTo(mapRef2.current);

                setGpxEndPoint(coordinates.lat + "," + coordinates.lng);
                mapRef2.current.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 10 });
            });
        });
        // Clean up the map instance when the component unmounts
        return () => mapRef2.current.remove();
    }, [mapStyle, mapMode]); // The empty dependency array ensures this useEffect runs only once

    useEffect(() => {
        // Create a map instance
        mapRef4.current = new mapboxgl.Map({
            container: 'map4', // Specify the container ID
            style: mapStyle, // Specify the map style
            center: [32.00, 35.00], // Specify the starting center
            zoom: 1, // Specify the starting zoom
        });
        mapRef4.current.on('load', () => {
            setMap(mapRef4.current);
        });

        mapRef4.current.on('style.load', function () {
            /*  mapRef4.current.on('click', function (e) {
                 var coordinates = e.lngLat;
                 new mapboxgl.Popup()
                 if (marker4Ref.current != null) {
                     marker4Ref.current.remove()
                 }
                 marker4Ref.current = new mapboxgl.Marker()
                     .setLngLat(coordinates)
                     // .setHTML('' + coordinates)
                     .addTo(mapRef4.current);
            
                 setGpxEndPoint(coordinates.lat + "," + coordinates.lng);
                 mapRef4.current.flyTo({center: [coordinates.lng, coordinates.lat], zoom: 10});
             }); */
        });
        // Clean up the map instance when the component unmounts
        return () => mapRef4.current.remove();
    }, [mapStyle, mapMode]);


    useEffect(() => {
        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from)
            if (location.state.from == 'create') {
                getPoi();
            }
            if (location.state.from != 'editWayPoint') {
                if (location.state.data && location.state.data.id) {
                    setGpxName(location.state.data.name);
                    setGPXFileName(location.state.data.url);
                    setDescription(location.state.data.description);
                    setAscent(location.state.data.ascent);
                    setCountry(location.state.data.country);
                    setDistance(location.state.data.distance);
                    setDuration(location.state.data.duration);
                    setImageName(location.state.data.photo);
                    setGpxStartPoint(location.state.data.starting_point.latitude + "," + location.state.data.starting_point.longitude);
                    setGpxEndPoint(location.state.data.ending_point.latitude + "," + location.state.data.ending_point.longitude);
                    setLevel(location.state.data.level);
                    setPersonalized(location.state.data.personalized);
                    setType(location.state.data.type);
                    setGpxId(location.state.data.id);
                    setFriendlyId(location.state.data.friendly_id);
                    setWayPointData(location.state.data.waypoints);

                    let tempTrailName = location.state.data.trail_types;
                    for (let i = 0; i < trailTypeArray.length; i++) {
                        if (trailTypeArray[i].name == tempTrailName) {
                            setTrailType(trailTypeArray[i])
                        }
                    }

                    let tempPoiIdArray = location.state.data.poi_set_sort && location.state.data.poi_set_sort.split(',') || [];
                    let temPoiSelectedData = location.state.data.poi_set || [];

                    getPoiEdit(tempPoiIdArray);

                    let tempSortSelectedPoiData = []
                    if (tempPoiIdArray && tempPoiIdArray.length > 0) {
                        for (let j = 0; j < tempPoiIdArray.length; j++) {
                            for (let i = 0; i < temPoiSelectedData.length; i++) {
                                if (tempPoiIdArray[j] == temPoiSelectedData[i].id) {
                                    tempSortSelectedPoiData.push(temPoiSelectedData[i])
                                }
                            }
                        }
                    }

                    setGpxData(location.state.data.url, location.state.data.waypoints)
                    markerRef.current = new mapboxgl.Marker()
                        .setLngLat([location.state.data.starting_point.longitude, location.state.data.starting_point.latitude])
                        .addTo(mapRef.current);

                    marker2Ref.current = new mapboxgl.Marker()
                        .setLngLat([location.state.data.ending_point.longitude, location.state.data.ending_point.latitude])
                        .addTo(mapRef2.current);

                    mapRef.current.flyTo({ center: [location.state.data.starting_point.longitude, location.state.data.starting_point.latitude], zoom: 10 });
                    mapRef2.current.flyTo({ center: [location.state.data.ending_point.longitude, location.state.data.ending_point.latitude], zoom: 10 });
                }
            }
            else {
                setShowAddWayPointPopUp(true)
            }
        }
        window.scrollTo(0, 0);
    }, [location.state]);

    const editOptions = {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true
    };

    const toolbarClick = args => {
        switch (args.item.text) {
            case 'Delete':
                args.cancel = false;
                break
            default:
                break
        }
    }

    async function CreateGPXFun() {
        let tempGpxStartPoint = gpxStartPoint.split(',');
        let tempGpxEndPoint = gpxEndPoint.split(',');
        if (gpxName == '' || gpxName == undefined) {
            setErrorMsg('Please Enter GPX Name.');
            return;
        }

        // if (imageName && imageName != '') { }
        // else {
        //     setErrorMsg('Please Upload GPX Image.')
        //     return;
        // }

        if (description == '' || description == undefined) {
            setErrorMsg('Please Enter GPX Description.');
            return;
        }
        if (country && country.id) {

        }
        else {
            setErrorMsg('Please Select Country.');
            return
        }

        if (gpxStartPoint == '' || gpxStartPoint == undefined) {
            setErrorMsg('Please Select GPX Start Point.');
            return;
        }

        if (gpxEndPoint == '' || gpxEndPoint == undefined) {
            setErrorMsg('Please Select GPX End Point.');
            return;
        }

        // if (poiIdArray && poiIdArray.length>0) {}
        // else{
        //     setErrorMsg(`Please Select POIs.`)
        //     return;
        // }
        try {
            const json = {
                starting_point: {
                    latitude: tempGpxStartPoint[0],
                    longitude: tempGpxStartPoint[1]
                },
                ending_point: {
                    latitude: tempGpxEndPoint[0],
                    longitude: tempGpxEndPoint[1]
                },
                waypoints: wayPointData,
                // poi: poiIdArray[0],
                poi: "4da159d7-c0f0-4a9d-ae05-3cbbccfb4979",
                url: gpxFileName,
                name: gpxName,
                photo: imageName,
                description: description,
                type: type,
                country: country.id,
                level: level,
                distance: distance,
                duration: duration,
                ascent: ascent,
                trail_types: type == 1 ? "" : trailType && trailType.name != "" ? trailType.name : "",
                // poi_set: poiIdArray,
                poi_set: ["4da159d7-c0f0-4a9d-ae05-3cbbccfb4979"],
                personalized: personalized
            };

            const response = await post(BASE_URL + '/gpx', json);

            if (response) {
                notify('GPX created successfully');
                navigate('/gpx');
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    async function editDayFun() {
        let tempGpxStartPoint = gpxStartPoint.split(',');
        let tempGpxEndPoint = gpxEndPoint.split(',');
        if (gpxName == '' || gpxName == undefined) {
            setErrorMsg('Please Enter GPX Name.');
            return;
        }
        // if (imageName && imageName != '') { }
        // else {
        //     setErrorMsg('Please Upload GPX Image.')
        //     return;
        // }

        if (description == '' || description == undefined) {
            setErrorMsg('Please Enter GPX Description.');
            return;
        }
        if (country && country.id) {

        }
        else {
            setErrorMsg('Please Select Country.');
            return
        }

        if (gpxStartPoint == '' || gpxStartPoint == undefined) {
            setErrorMsg('Please Select GPX Start Point.');
            return;
        }

        if (gpxEndPoint == '' || gpxEndPoint == undefined) {
            setErrorMsg('Please Select GPX End Point.');
            return;
        }

        // if (poiIdArray && poiIdArray.length > 0) { }
        // else {
        //     setErrorMsg(`Please Select POIs.`)
        //     setTimeout(() => {
        //         setErrorMsg("")
        //     }, 3000);
        //     return;
        // }

        try {
            let json = {
                starting_point: {
                    latitude: tempGpxStartPoint[0],
                    longitude: tempGpxStartPoint[1]
                },
                ending_point: {
                    latitude: tempGpxEndPoint[0],
                    longitude: tempGpxEndPoint[1]
                },
                // waypoints: [
                //     {
                //         "id": "string",
                //         "location": {
                //             "latitude": 0,
                //             "longitude": 0
                //         },
                //         "name": "string",
                //         "type": 0,
                //         "description": "string",
                //         "photo": "string"
                //     }
                // ],
                waypoints: wayPointData,
                // poi: poiIdArray[0],
                poi: "4da159d7-c0f0-4a9d-ae05-3cbbccfb4979",
                url: gpxFileName,
                name: gpxName,
                photo: imageName,
                description: description,
                type: type,
                country: country.id,
                level: level,
                distance: distance,
                duration: duration,
                ascent: ascent,
                trail_types: type == 1 ? "" : trailType && trailType.name != "" ? trailType.name : "",
                // poi_set: poiIdArray,
                poi_set: ["4da159d7-c0f0-4a9d-ae05-3cbbccfb4979"],
                personalized: personalized
            }

            const response = await patch(BASE_URL + '/gpx/' + gpxId, json);
            if (response.status == 200) {
                notify('GPX updated successfully');
                navigate('/gpx');
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    const rowSelected = () => {
        // if (gridRef && gridRef.current) {
        //   const tempSelectedRecords = gridRef.current.getSelectedRecords()

        //   let tempData = [...selectedRecords]
        //   let tempPoiIdArray = [...poiIdArray]

        //   if (selectedRecords && selectedRecords.length > 0) {
        //     for (let i = 0; i < tempSelectedRecords.length; i++) {
        //       let status = false
        //       for (let j = 0; j < selectedRecords.length; j++) {
        //         if (tempSelectedRecords[i].id == selectedRecords[j].id) {
        //           status = true
        //         }
        //       }
        //       if (!status) {
        //         tempData.push(tempSelectedRecords[i])
        //         tempPoiIdArray.push(tempSelectedRecords[i].id)
        //       }
        //     }
        //     setPoiIdArray(tempPoiIdArray)
        //     setSelectedRecords(tempData)
        //   }
        //   else {
        //     setSelectedRecords(tempSelectedRecords)
        //     tempPoiIdArray.push(tempSelectedRecords[0].id)
        //     setPoiIdArray(tempPoiIdArray)
        //   }
        // }
    }

    const rowDeselected = args => {
        // try {
        //   let tempSelectedRecords = [...selectedRecords]
        //   for (let i = 0; i < tempSelectedRecords.length; i++) {
        //     if (args.data.length > 1) {
        //       for (let j = 0; j < args.data.length; j++) {
        //         if (tempSelectedRecords[i].id == args.data[j].id) {
        //           tempSelectedRecords.splice(i, 1)
        //         }
        //       }
        //     } else {
        //       if (tempSelectedRecords[i].id == args.data.id) {
        //         tempSelectedRecords.splice(i, 1)
        //       }
        //     }
        //   }
        //   setSelectedRecords(tempSelectedRecords)
        //   let tempIdArray = []
        //   for (let i = 0; i < tempSelectedRecords.length; i++) {
        //     tempIdArray.push(tempSelectedRecords[i].id)
        //   }
        //   setPoiIdArray(tempIdArray)
        // }
        // catch (ex) {

        // }
    }


    const onDragEnd = (result) => {
        if (!result.destination) {
            return; // Drop outside the list
        }

        const reorderedItems = Array.from(wayPointData);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setWayPointData(reorderedItems);
    };

    function handleUpload(url) {
        setImageName(url);
        setShowImagePopUp(false);
        setErrorMsg("");
    }

    function handleGPXUpload(url) {
        setGPXFileName(url);
        setGpxData(url, []);
        setShowGPXFilePopUP(false);
        setErrorMsg("");
    }

    function deleteWayPoint(item, index) {
        let tempWayPointData = [...wayPointData];
        tempWayPointData.splice(index, 1)
        setWayPointData(tempWayPointData)
    }

    function deleteAllWayPoint(item, index) {
        let tempWayPointData = [...wayPointData];
        tempWayPointData.splice(index, 1)
        setWayPointData(tempWayPointData);
    }

    async function setGpxData(gpxLoc, wayPoint) {
        if (gpxLoc) {
            fetch(gpxLoc).then((r) => {
                r.text().then((d) => {
                    setTimeout(function () { //Start the timer
                        handleGpx($(d));//After 1 second, set render to true
                    }.bind(this), 1000);
                });
            });
        }
        if (wayPoint && wayPoint.length > 0) {
            setTimeout(function () {
                wayPoint.forEach((p) => {
                    if (p.location.latitude && p.location.longitude)
                        addVgMarker({ lng: p.location.longitude, lat: p.location.latitude }, p.id);
                })
            }.bind(this), 3000);
        }
    }

    function handleGpx(xmlData) {
        const geojson = gpx(xmlData[2]);
        if (typeof mapRef4.current.getLayer('day-route-line') !== 'undefined') {
            mapRef4.current.removeLayer('day-route-line').removeSource('day-route');
        }
        mapRef4.current.addSource('day-route', {
            type: 'geojson',
            data: geojson,
        });

        mapRef4.current.addLayer({
            id: 'day-route-line',
            type: 'line',
            source: 'day-route',
            paint: {
                'line-color': '#15cc09',
                'line-width': 4,
            },
        });

        var coordinates = geojson.features[0].geometry.coordinates;
        var bounds = coordinates.reduce(function (bounds, coord) {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        mapRef4.current.fitBounds(bounds, {
            padding: 20,
        });
    }

    function addVgMarker(coordinates, id1, type = false) {
        //const id = _uniqueId('vgmarker-');
        /*    const id = 'vgmarker-' + id1;
           let marker;
           var el = document.createElement('div');
           el.className = 'marker';
           el.id = id;
           el.poiid = id1;
               el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/poi.svg')`; */
        marker4Ref.current = new mapboxgl.Marker().setLngLat(coordinates).addTo(mapRef4.current);
    }

    function addMarkerInGPXMap(localWaypoints) {
        for (let i = 0; i < localWaypoints.length; i++) {
            let coordinates = { lat: localWaypoints[i].location.latitude, lng: localWaypoints[i].location.longitude }
            marker4Ref.current = new mapboxgl.Marker().setLngLat(coordinates).addTo(mapRef4.current);
        }
        //marker4Ref.current =  new mapboxgl.Marker().setLngLat(coordinates).addTo(mapRef4.current);
    }

    return (
        <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
            <>
                <div className="flex justify-between items-center h-20">
                    <Header category="" title={fromStatus == "create" ? "Add New GPX" : `Edit GPX ${friendlyId}`} />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }} className="mb-10 mr-20">
                        {personalized ?
                            <img src={BlueCheck} style={{ height: 25, width: 25, cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} />
                            :
                            <div style={{ borderRadius: 4, height: 25, width: 25, border: '2px solid #2D96F3', cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} >
                            </div>
                        }
                        <div style={{ fontSize: 22, fontWeight: 'bold' }}>Personalized</div>
                        <button
                            onClick={toggleDirection}
                            style={{
                                backgroundColor: "#007BFF", // Blue color
                                color: "#fff", // White text
                                border: "none",
                                borderRadius: "8px", // Rounded corners
                                padding: "10px 20px",
                                fontSize: "16px",
                                cursor: "pointer",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Shadow effect
                                transition: "background-color 0.3s ease",
                            }}
                            onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
                            onMouseOut={(e) => (e.target.style.backgroundColor = "#007BFF")}
                        >
                            כיוון תצוגה ({direction === 'ltr' ? 'אנגלית' : 'עברית'})
                        </button>
                    </div>
                </div>
                <div className="countryFormMainDiv p-10">
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 150 }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
                                <span style={{ fontSize: 20, color: '#000' }}>Name</span>
                                <textarea
                                    className="InputBoxCss"
                                    style={{ width: 350 }}
                                    value={gpxName}
                                    dir={direction}
                                    rows="4"
                                    onChange={(e) => setGpxName(e.target.value)}
                                    onFocus={() => setErrorMsg("")}
                                />
                            </div>
                            <div
                                style={{
                                    width: '60%',
                                    marginTop: 30
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>
                                    Country
                                </span>

                                <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
                                    {country && country.id ?
                                        <span style={{ color: '#000' }}>{country.name}</span>
                                        :
                                        <span style={{ color: 'gray' }}>Select Country</span>
                                    }
                                    <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                                </div>

                                {showCountrySelect &&
                                    <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                                        <div className="dropDownDiv">
                                            {countryData && countryData.length > 0 && countryData.map((item, index) => (
                                                <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                    </ClickAwayListener>
                                }
                            </div>

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10 }}>
                            <span style={{ fontSize: 20, color: '#000' }}>Description</span>
                            <textarea aria-invalid="false" id="txtDescription" dir={direction} rows="6" class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss" style={{ width: 400 }} value={description} onChange={(e) => { setDescription(e.target.value) }} onFocus={() => (setErrorMsg(""))} />
                        </div>

                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
                                <span style={{ fontSize: 20, color: '#000' }}>GPX file</span>
                                {gpxFileName && gpxFileName != "" ?
                                    <>
                                        <a
                                            href={gpxFileName}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                display: 'block',
                                                width: 150,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}
                                            title="Click here to download the GPX file"
                                        >
                                            {gpxFileName}
                                        </a>

                                        <div className="changeImg" onClick={() => { setShowGPXFilePopUP(true) }}>
                                            Change GPX File
                                        </div>
                                    </>
                                    :
                                    <SimpleFileUpload
                                        apiKey={SimpleFileUploadAPIKey}
                                        onSuccess={handleGPXUpload}
                                        width="150"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className='selectLatLongDiv' style={{ height: 'auto' }}>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    gap: 10,
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>Type</span>

                                <div className="selectPOITypeMainDiv">
                                    <div>
                                        <div className="selectPOITypeMainIconDiv" onClick={() => { setType(1); setTrailType({}); setImageName(""); setLevel(0); setDistance(""); setDuration(""); setAscent("") }} >
                                            {type == 1 && (
                                                <div className="selectPOITypeMainIconSelectedDiv"></div>
                                            )}
                                        </div>
                                        <div>Embeded In Day</div>
                                    </div>
                                    <div>
                                        <div className="selectPOITypeMainIconDiv" onClick={() => { setType(2) }} >
                                            {type == 2 && (
                                                <div className="selectPOITypeMainIconSelectedDiv"></div>
                                            )}
                                        </div>
                                        <div>Trail</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {type == 2 &&

                        <div className='selectLatLongDiv' style={{ height: 'auto' }}>
                            <div>
                                <div
                                    style={{
                                        width: '60%'
                                    }}
                                >
                                    <span style={{ fontSize: 20, color: '#000' }}>
                                        Level
                                    </span>

                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20, marginTop: 10 }}>
                                        <div className={level == 1 ? "gpxLevelNameBtnActive" : "gpxLevelNameBtn"} onClick={() => { setLevel(1) }}>EASY</div>
                                        <div className={level == 2 ? "gpxLevelNameBtnActive" : "gpxLevelNameBtn"} onClick={() => { setLevel(2) }}>MODERATE</div>
                                        <div className={level == 3 ? "gpxLevelNameBtnActive" : "gpxLevelNameBtn"} onClick={() => { setLevel(3) }}>HARD</div>
                                    </div>

                                </div>

                                <div className="gpxTrailOptions">
                                    <div>
                                        <span style={{ fontSize: 20, color: '#000' }}>
                                            Distance
                                        </span>
                                        <input type="text" className="InputBoxCss" style={{ width: 200 }} value={distance} onChange={(e) => { setDistance(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                                    </div>
                                    <div>
                                        <span style={{ fontSize: 20, color: '#000' }}>
                                            Duration
                                        </span>
                                        <input type="text" className="InputBoxCss" style={{ width: 200 }} value={duration} onChange={(e) => { setDuration(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                                    </div>
                                    <div>
                                        <span style={{ fontSize: 20, color: '#000' }}>
                                            Ascent
                                        </span>
                                        <input type="text" className="InputBoxCss" style={{ width: 200 }} value={ascent} onChange={(e) => { setAscent(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                                    </div>
                                    <div>
                                        <span style={{ fontSize: 20, color: '#000' }}>
                                            Type
                                        </span>
                                        <div className='InputBoxCss' style={{ width: 200, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px' }} onClick={() => { setShowTrailTypeSelect(!showTrailTypeSelect) }}>
                                            {trailType && trailType.id ?
                                                <span style={{ color: '#000' }}>{trailType.name}</span>
                                                :
                                                <span style={{ color: 'gray' }}>Select Trail Type</span>
                                            }
                                            <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                                        </div>
                                        {showTrailTypeSelect &&
                                            <ClickAwayListener onClickAway={() => { setShowTrailTypeSelect(false) }}>
                                                <div className="dropDownDiv" style={{ marginTop: 80, width: 200 }}>
                                                    {trailTypeArray && trailTypeArray.length > 0 && trailTypeArray.map((item, index) => (
                                                        <div className="countryDropDownItemDiv" onClick={() => { setTrailType(item); setShowTrailTypeSelect(false) }}>
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </ClickAwayListener>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
                                    <span style={{ fontSize: 20, color: '#000' }}>Image</span>
                                    {imageName && imageName != "" ?
                                        <>
                                            <img src={imageName} style={{ width: 250, height: 150, borderRadius: 10 }} alt="" />
                                            <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                                                Change Image
                                            </div>
                                        </>
                                        :
                                        <SimpleFileUpload
                                            apiKey={SimpleFileUploadAPIKey}
                                            onSuccess={handleUpload}
                                            width="350"
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                    }
                    <div className="selectLatLongDiv">
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
                                <span style={{ fontSize: 20, color: '#000' }}>Start Location</span>
                                {/* <input type="text" className="InputBoxCss" style={{ width: 350 }} placeholder="Select start ponits on map." value={gpxStartPoint}  /> */}
                                <input
                                    type='text'
                                    className='InputBoxCss'
                                    style={{ width: 300 }}
                                    placeholder='32.00, 34.00'
                                    value={gpxStartPoint}
                                    onChange={e => {
                                        let latlng = e.target.value;
                                        if (e.target.value === '') {
                                            setGpxStartPoint('');
                                            if (markerRef.current != null) {
                                                markerRef.current.remove();
                                            }
                                        } else {
                                            setGpxStartPoint(latlng);
                                            if (markerRef.current != null) {
                                                markerRef.current.remove();
                                            }
                                        }
                                        latlng = latlng.trim();
                                        let originalLatLng = latlng;
                                        latlng = latlng.split(',');
                                        if (latlng.length === 2) {
                                            setGpxStartPoint(originalLatLng);
                                            markerRef.current = new mapboxgl.Marker()
                                                .setLngLat([latlng[1].trim(), latlng[0].trim()])
                                                .addTo(mapRef.current);
                                            mapRef.current.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 10 });
                                        }
                                    }}
                                    onFocus={() => setErrorMsg('')}
                                />
                            </div>
                            <div className="outerMapContainer">
                                <div id="map" style={{ width: '100%', height: '100%' }}></div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
                                <span style={{ fontSize: 20, color: '#000' }}>End Location</span>
                                {/* <input type="text" className="InputBoxCss" style={{ width: 350 }} placeholder="Select end ponits on map." value={gpxEndPoint} /> */}

                                <input
                                    type='text'
                                    className='InputBoxCss'
                                    style={{ width: 300 }}
                                    placeholder='32.00, 34.00'
                                    value={gpxEndPoint}
                                    onChange={e => {
                                        let latlng = e.target.value;
                                        if (e.target.value === '') {
                                            setGpxEndPoint('');
                                            if (marker2Ref.current != null) {
                                                marker2Ref.current.remove();
                                            }
                                        } else {
                                            setGpxEndPoint(latlng);
                                            if (marker2Ref.current != null) {
                                                marker2Ref.current.remove();
                                            }
                                        }
                                        latlng = latlng.trim();
                                        let originalLatLng = latlng;
                                        latlng = latlng.split(',');
                                        if (latlng.length === 2) {
                                            setGpxEndPoint(originalLatLng);
                                            marker2Ref.current = new mapboxgl.Marker()
                                                .setLngLat([latlng[1].trim(), latlng[0].trim()])
                                                .addTo(mapRef2.current);
                                            mapRef2.current.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 10 });
                                        }
                                    }}
                                    onFocus={() => setErrorMsg('')}
                                />

                            </div>
                            <div className="outerMapContainer">
                                <div id="map2" style={{ width: '100%', height: '100%' }}></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="outerMapContainer" style={{ width: 950 }}>
                            <div id="map4" style={{ width: '100%', height: '100%' }}></div>
                        </div>
                    </div>


                    {/* <div style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50 }}>
                        <div style={{ width: '50%', }}>
                            <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
                                Select POIs:
                            </div>
                            <GridComponent
                                dataSource={poiData}
                                toolbar={toolbarOptions}
                                allowPaging
                                allowSorting
                                pageSettings={{ pageCount: 3, pageSize: 12 }}
                                rowSelected={rowSelected}
                                rowDeselected={rowDeselected}
                                ref={gridRef}
                                style={{ width: '100%' }}
                            >
                                <ColumnsDirective>
                                    {gpxPoiGrid.map((item, index) => (
                                        <ColumnDirective key={index} {...item} />
                                    ))}
                                </ColumnsDirective>
                                <Inject services={[Edit, Toolbar]} />
                            </GridComponent>
                        </div>

                        <div style={{ width: '50%', }}>
                            <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
                                Order Selected POIs (Drag to order):
                            </div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <div style={{ minHeight: selectedRecords && selectedRecords.length > 0 ? 'auto' : 150, width: '100%', border: '1px solid #e0e0e0', }}>
                                                {selectedRecords && selectedRecords.length > 0 ?
                                                    <div>
                                                        {selectedRecords.map((item, index) => (

                                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div style={{ padding: '15px 15px', borderBottom: snapshot.isDragging ? '1px solid blue' : '1px solid #e0e0e0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 40, fontSize: 13, cursor: 'default' }}>
                                                                            <div style={{ width: 15, height: 15, border: '2.5px solid black', cursor: 'move' }}>
                                                                            </div>
                                                                            <div style={{ width: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                                {item.id}
                                                                            </div>
                                                                            <div style={{ width: 100, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                                {item.name}
                                                                            </div>
                                                                        </div>

                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    </div>
                                                    :
                                                    <div style={{ height: 150, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, color: '#908d8d' }}>
                                                        No Data
                                                    </div>
                                                }
                                            </div>

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>


                </div>
 */}


                    <div style={{ marginTop: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50 }}>
                            <div style={{ width: '100%', }}>
                                <div style={{ fontSize: 20, fontWeight: 800, marginBottom: 15 }}>
                                    Local POI
                                </div>
                                {/* <GridComponent
                                        dataSource={wayPointData}
                                        toolbar={toolbarOptions}
                                        allowPaging
                                        allowSorting
                                        editSettings={editOptions}
                                        toolbarClick={toolbarClick}
                                        pageSettings={{ pageCount: 3, pageSize: 12 }}
                                        // rowSelected={rowSelected}
                                        // rowDeselected={rowDeselected}
                                        ref={gridRef}
                                        style={{ width: '100%' }}
                                    >
                                        <ColumnsDirective>
                                            {wayPointGrid.map((item, index) => (
                                                <ColumnDirective key={index} {...item} />
                                            ))}
                                        </ColumnsDirective>
                                        <Inject services={[Edit, Toolbar]} />
                                    </GridComponent> */}

                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                <div style={{ minHeight: wayPointData && wayPointData.length > 0 ? 'auto' : 150, width: '100%', border: '1px solid #e0e0e0', }}>
                                                    <div style={{ padding: '10px 10px', borderBottom: snapshot.isDragging ? '1px solid blue' : '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15, fontSize: 13, cursor: 'default', background: '#fafafa' }}>
                                                        <img src={DragIcon} style={{ width: 15, height: 15, cursor: 'move' }} />
                                                        <div style={{ width: 260, textAlign: 'center' }}>
                                                            ID
                                                        </div>
                                                        <div style={{ width: 150, textAlign: 'center' }}>
                                                            Title
                                                        </div>
                                                        <div style={{ width: 260, textAlign: 'center' }}>
                                                            Country
                                                        </div>
                                                        <div style={{ width: 200, textAlign: 'center' }}>
                                                            Description
                                                        </div>
                                                        <div style={{ width: 180, textAlign: 'center' }}>
                                                            Location
                                                        </div>
                                                        <div style={{ width: 150, textAlign: 'center' }}>
                                                            Type
                                                        </div>
                                                        <div style={{ width: 25, textAlign: 'center' }}>
                                                            Edit
                                                        </div>
                                                        <div style={{ width: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', cursor: 'pointer' }} onClick={() => { setDeleteConfirm(true) }}>
                                                            {/* <div style={{ width: 15, height: 15, border: '2.5px solid black', cursor: 'pointer' }}>
                                                                                    </div> */}
                                                            Delete All
                                                        </div>
                                                    </div>
                                                    {wayPointData && wayPointData.length > 0 ?
                                                        <div>
                                                            {wayPointData.map((item, index) => (

                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div style={{ padding: '10px 10px', borderBottom: snapshot.isDragging ? '1px solid blue' : '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15, fontSize: 13, cursor: 'default' }}>
                                                                                {/* <div style={{ width: 15, height: 15, border: '2.5px solid black', cursor: 'move' }}>
                                                                                    </div> */}
                                                                                <img src={DragIcon} style={{ width: 15, height: 15, cursor: 'move' }} />
                                                                                <div style={{ width: 260, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                                    {item ? item.friendly_id : "newPOI"}
                                                                                </div>
                                                                                <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                                    {item ? item.name : ""}
                                                                                </div>
                                                                                <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                                    {item.country ? item.country.name : ""}
                                                                                </div>
                                                                                <div style={{ width: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                                    {item ? item.description : ""}
                                                                                </div>
                                                                                <div style={{ width: 180, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                                    {(item && item.location) ? `${item.location.latitude.toFixed(4)},${item.location.longitude.toFixed(4)}` : ""}
                                                                                </div>
                                                                                <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                                                    {item.type == 1 ? "LOCAL HERO TIP" : item.type == 2 ? "RIGHT" : item.type == 3 ? "LEFT" : item.type == 4 ? "Branching / fork to the right" : item.type == 5 ? "Branching / fork to the left" : item.type == 6 ? "Point of interest" : item.type == 7 ? "Information Icon" : item.type == 8 ? "Icon for swimming" : item.type == 9 ? "Rafting / kayaking icon" : ""}
                                                                                </div>
                                                                                <div style={{ width: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <img
                                                                                        src={EditIcon}
                                                                                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                                                                                        alt="Image"
                                                                                        onClick={() => { setWayPointItem(item); setWayPointIndex(index); setShowAddWayPointPopUp(true) }}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ width: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <img src={DeleteIcon} style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => { deleteWayPoint(item, index) }} />
                                                                                </div>
                                                                            </div>

                                                                            {provided.placeholder || ""}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div style={{ height: 150, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, color: '#908d8d' }}>
                                                            No Data
                                                        </div>
                                                    }
                                                </div>

                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>


                                <div style={{ height: 20 }}></div>
                                <span className="addLocalPoiWayPointText" onClick={() => { setShowAddWayPointPopUp(true) }} >
                                    + ADD LOCAL POI \ WAYPOINT
                                </span>
                            </div>
                        </div>
                    </div>

                    {errorMsg != '' &&
                        <div style={{ textAlign: 'center', color: 'red', fontSize: 16, marginTop: 30, }}>
                            {errorMsg}
                        </div>
                    }
                    {fromStatus == "create" ?
                        <div className="AddBtn" onClick={() => { CreateGPXFun() }}>
                            Add
                        </div>
                        :
                        <div className="AddBtn" onClick={() => { editDayFun() }}>
                            Update GPX {friendlyId}
                        </div>
                    }
                </div>
            </>

            {
                showAddWayPointPopUp &&
                <>
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 99
                        }}
                    >
                        <ClickAwayListener onClickAway={() => { setShowAddWayPointPopUp(false); setWayPointItem({}) }}>
                            <div
                                style={{
                                    height: '90%',
                                    width: '90%',
                                    overflow: 'scroll',
                                    background: '#fff',
                                    borderRadius: 10,
                                    padding: '45px'
                                }}
                            >
                                <CreateWayPoints wayPointIndex={wayPointIndex} showAddWayPointPopUp={showAddWayPointPopUp} setShowAddWayPointPopUp={setShowAddWayPointPopUp} wayPointData={wayPointData} setWayPointData={setWayPointData} wayPointItem={wayPointItem} setWayPointItem={setWayPointItem} addMarkerInGPXMap={addMarkerInGPXMap} />
                            </div>
                        </ClickAwayListener>
                    </div>

                </>
            }

            {
                showImagePopUp && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => { setShowImagePopUp(false) }}
                    >
                        <div
                            style={{
                                background: '#fff',
                                borderRadius: 10,
                                padding: '15px'
                            }}
                        >
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleUpload}
                                width="350"
                            // onDrop={handleOnDrop}
                            />
                        </div>
                    </div>
                )
            }

            {
                showGPXFilePopUP && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => { setShowGPXFilePopUP(false) }}
                    >
                        <div
                            style={{
                                background: '#fff',
                                borderRadius: 10,
                                padding: '15px'
                            }}
                        >
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleGPXUpload}
                                width="150"
                            />
                        </div>
                    </div>
                )
            }

            {
                deleteConfirm && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <ClickAwayListener onClickAway={() => { setDeleteConfirm(false) }} >
                            <div
                                style={{
                                    width: 400,
                                    height: 200,
                                    background: '#fff',
                                    borderRadius: 10,
                                    padding: '15px 20px'
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 'bold',
                                        color: '#000',
                                        marginBottom: 15
                                    }}
                                >
                                    Delete Alert
                                </div>
                                <div style={{ fontSize: 18, color: '#000', marginBottom: 10 }}>
                                    Are you sure you want to delete all <br /> Local POI\Waypoint
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        gap: 20
                                    }}
                                >
                                    <div
                                        className='confirmOkBtn'
                                        onClick={() => {
                                            setDeleteConfirm(false)
                                        }}
                                    >
                                        Cancle
                                    </div>

                                    <div
                                        className='confirmOkBtn'
                                        onClick={() => {
                                            setWayPointData([])
                                            setDeleteConfirm(false)
                                        }}
                                    >
                                        Yes
                                    </div>

                                </div>
                            </div>
                        </ClickAwayListener>
                    </div>
                )
            }
        </div >
    );
};

export default CreateGPX;
