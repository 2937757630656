import React from "react";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

import IndieCircleLogo from '../data/Indie_circle_logo.png'

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
  useStateContext();
  
  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className={!activeMenu ? "ml-0":"ml-0 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10"}>
      {/* {activeMenu && ( */}
        <>
          <div className={activeMenu?"flex justify-between items-center":"flex justify-center items-center"}>
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className={`items-center gap-3 ${activeMenu ? 'ml-3' : 'ml-0'} mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900`}
            >
              {/* <SiShopware /> */}
              <img src={IndieCircleLogo} style={!activeMenu?{width:30,height:30}:{width:50,height:50}}/>
              {activeMenu &&
               <span>iNDiE 4x4</span>
              }
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className={activeMenu?"mt-8":"mt-6"}>
            {links.map((item) =>  (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => (!activeMenu?{
                      backgroundColor: isActive ? currentColor : "",
                      padding:'5px',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      borderRadius:4,
                    }:{ backgroundColor: isActive ? currentColor : ""})}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                 {activeMenu && <span className="capitalize ">{link.displayName? link.displayName : link.name}</span>}
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      {/* )} */}
    </div>
  );
};

export default Sidebar;
