import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './NewMain.css'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";

import { hotelGrid } from "../../data/dummy";
import { Header } from "../../components";
import CreateAddIcon from "../../data/createAddIcon.png"
import { BASE_URL, deleteCall, get } from "../../Services/Calls";

const HotelsPrices = () => {

  const navigate = useNavigate()
  const FilterOptions = {
    type: 'Excel'
  };
  const selectionSettings = { checkboxOnly: true };
  const toolbarOptions = ["Delete",'Search'];
  const [hotelData,setHotelData] = useState([])


  const [selectedRecords, setSelectedRecords] = useState([]) // Selected records to be deleted

  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const editOptions = { allowEditing: false, allowAdding: true, allowDeleting: true };

  useEffect(() => {
    getHotelData()   
}, [])


const getHotelData = async () => {
  try{
  const response = await get(BASE_URL + "/hotels-prices-list");
  if(response &&  response.length>0 ){
    setHotelData(response)
  }
}
catch(ex){
  alert(ex)
}
};

const toolbarClick = args => {
  switch (args.item.text) {
    case 'Delete':
      args.cancel = true;
      setDeleteConfirm(true)
      break
    default:
      break
  }
}

const handleDelete = async() => {
  try {
    for (let i = 0; i < selectedRecords.length; i++) {
      const response = deleteCall(
       await BASE_URL + '/hotels-prices-list/' + selectedRecords[i].id
      )
      if (response) {
       
      }
    }
    setTimeout(() => { 
      getHotelData()  
      setDeleteConfirm(false)
    }, 200);

  }
  catch(ex){
    alert(ex)
  }
}



let grid

const rowSelected = () => {
  if (grid) {
    const tempSelectedrecords = grid.getSelectedRecords()
    setSelectedRecords(tempSelectedrecords)
  }
}

const rowDeselected = args => {
  let tempSelectedRecords = [...selectedRecords]
  for (let i = 0; i < tempSelectedRecords.length; i++) {
    if (args.data.length > 1) {
      for (let j = 0; j < args.data.length; j++) {
        if (tempSelectedRecords[i].id == args.data[j].id) {
          tempSelectedRecords.splice(i, 1)
        }
      }
    } else {
      if (tempSelectedRecords[i].id == args.data.id) {
        tempSelectedRecords.splice(i, 1)
      }
    }
  }
  setSelectedRecords(tempSelectedRecords)
}

  return (
    <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
      <div className="flex justify-between items-center h-20">
        <Header category="" title="Hotel Price List" />
        <div style={{width:225}} className="w-150 border flex justify-end items-center mb-8 createBtn" onClick={()=>{navigate('/createHotel',{state:{from:'create',data:{}}})}}>
          <span>Add New Hotel</span>
          <img src={CreateAddIcon} style={{width:15,height:15}} alt="" />
        </div>
      </div>
      <GridComponent 
      dataSource={hotelData} 
      editSettings={editOptions} 
      toolbar={toolbarOptions}
      filterSettings={FilterOptions}
      selectionSettings={selectionSettings}
      allowFiltering={true}
      allowPaging
      allowSorting
      pageSettings={{ pageCount: 5,pageSize: 10 }}
        toolbarClick={toolbarClick}
        rowSelected={rowSelected}
        rowDeselected={rowDeselected}
        ref={g => (grid = g)}
      >
      <ColumnsDirective>
          {hotelGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
    <Inject services={[Edit, Toolbar, Page, Filter]}/>
  </GridComponent>
  {deleteConfirm && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: 400,
              height: 200,
              background: '#fff',
              borderRadius: 10,
              padding: '15px 20px'
            }}
          >
            <div
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                color: '#000',
                marginBottom: 15
              }}
            >
              Delete Alert
            </div>
            <div style={{ fontSize: 18, color: '#000', marginBottom: 10 }}>
            {selectedRecords.length>0?"Are you sure you want to delete selected rows.":"Please select at-least one row."}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap:20
              }}
            >
              <div
                className='confirmOkBtn'
                onClick={() => {
                  getHotelData()
                  setDeleteConfirm(false)
                }}
              >
             {selectedRecords.length>0?"Cancle":"Ok"}   
              </div>
              {selectedRecords.length>0 &&
              <div
                className='confirmOkBtn'
                onClick={() => {
                  handleDelete()
                }}
              >
                Yes
              </div>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelsPrices;
