import React, { useEffect, useState } from 'react';
import './NewMain.css';
import { Header } from '../../components';
import { useLocation } from 'react-router-dom';

const ChangelogValues = () => {
    const location = useLocation();
    const [userEmail, setUserEmail] = useState('');
    const [previousValue, setPreviousValue] = useState({});
    const [newValue, setNewValue] = useState({});
    const [action, setAction] = useState('');
    const [friendlyId, setFriendlyId] = useState('');
    const [entity, setEntity] = useState('');

    useEffect(() => {
        if (location && location.state) {
            setUserEmail(location.state.data.userEmail);
            if (location.state.data.previousValue) {
                setPreviousValue(JSON.parse(location.state.data.previousValue));
            }
            if (location.state.data.newValue) {
                setNewValue(JSON.parse(location.state.data.newValue));
            }
            setAction(location.state.data.action);
            setFriendlyId(location.state.data.friendly_id);
            setEntity(location.state.data.entity);
        }
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
            <div className='flex justify-between items-center h-20'>
                <Header
                    category=''
                    title={`Changelog Friendly Id: ${friendlyId}`}
                />
            </div>
            <div style={{ marginBottom: 30 }}>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>Action:</h2>
                <h2 style={{ fontSize: '24px' }}>{action}</h2>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>Entity:</h2>
                <h2 style={{ fontSize: '24px' }}>{entity}</h2>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>User Email:</h2>
                <h2 style={{ fontSize: '24px' }}>{userEmail}</h2>
            </div>
            {Object.keys(previousValue).length > 0 && <div style={{ marginBottom: 30 }}>
                <h2 style={{ fontSize: '24px', marginBottom: 30, fontWeight: 'bold' }}>Previous Value</h2>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        <tr>
                            <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Key</th>
                            <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Value</th>
                        </tr>
                        {Object.entries(previousValue).map(([key, value], index) => (
                            <tr key={key} style={{ backgroundColor: index % 2 === 0 ? '#dddddd' : 'transparent' }}>
                                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'left' }}>{key}</td>
                                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'left' }}>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}

            {/* Tabla de NewValue */}
            {Object.keys(newValue).length > 0 && <div style={{ marginBottom: 30 }}>
                <h2 style={{ fontSize: '24px', marginBottom: 30, fontWeight: 'bold' }}>New Value</h2>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        <tr>
                            <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Key</th>
                            <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Value</th>
                        </tr>
                        {Object.entries(newValue).map(([key, value], index) => (
                            <tr key={key} style={{ backgroundColor: index % 2 === 0 ? '#dddddd' : 'transparent' }}>
                                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'left' }}>{key}</td>
                                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'left' }}>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </div>
    );
};

export default ChangelogValues;
