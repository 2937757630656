import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL, get, put } from '../../Services/Calls';
import { notify } from '../../Services/Utils';
import { Header } from '../../components';
import './NewMain.css';

const EditUser = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [errorMsg, setErrorMsg] = useState('');
    const [fromStatus, setFromStatus] = useState('');
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [keepMeUpdated, setKeepMeUpdated] = useState('');
    const [lastLoginAt, setLastLoginAt] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [friendlyId, setFriendlyId] = useState('');
    const [selfGuidedData, setSelfGuidedData] = useState([]);
    const [packageId, setPackageId] = useState('');
    const [tripId, setTripId] = useState('');
    const [createdAt, setCreatedAt] = useState(false);
    const [deleteActives, setDeleteActives] = useState(false);

    useEffect(() => {
        (async () => {
            await getSelfGuided();
        })();
    }, []);

    const getSelfGuided = async () => {
        try {
            const response = await get(BASE_URL + '/self-guided-packages');
            if (response && response.results && response.results.length > 0) {
                setSelfGuidedData(response.results);
            }
        } catch (ex) {
            alert(ex);
        }
    };

    useEffect(() => {
        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from);
            if (location.state.data && location.state.data.id) {
                setUserId(location.state.data.id);
                setFirstName(location.state.data.firstName);
                setLastName(location.state.data.lastName);
                setKeepMeUpdated(location.state.data.keep_me_updated);
                let dateObj = new Date(location.state.data.last_login_at);
                let day = String(dateObj.getDate()).padStart(2, '0');
                let month = String(dateObj.getMonth() + 1).padStart(2, '0');
                let year = dateObj.getFullYear();
                let formattedDate = `${day}/${month}/${year}`;
                setLastLoginAt(formattedDate);
                setRole(location.state.data.role);
                setFromStatus(location.state.data.formStatus);
                setPackageId(location.state.data.self_guided_package_id);
                setTripId(location.state.data.active_trip_id);
                setEmail(location.state.data.email);
                setFriendlyId(location.state.data.friendly_id);

                dateObj = new Date(location.state.data.createdAt);
                day = String(dateObj.getDate()).padStart(2, '0');
                month = String(dateObj.getMonth() + 1).padStart(2, '0');
                year = dateObj.getFullYear();
                formattedDate = `${day}/${month}/${year}`;
                setCreatedAt(formattedDate);
            }
            window.scrollTo(0, 0);
        }
    }, [location]);

    async function createUserFun() { }

    async function editUserFun() {
        try {
            let userUpdateData = {};

            if (deleteActives) {
                userUpdateData = {
                    "active_trip_id": null,
                    "self_guided_package_id": null
                };
            } else {
                userUpdateData = {
                    "active_trip_id": tripId,
                    "self_guided_package_id": packageId
                };
            }

            const response = await put(BASE_URL + "/users/" + userId, userUpdateData);
            if (response.status === 200) {
                notify('User updated successfully');
                navigate('/');
            } else {
                setErrorMsg('Failed to update user. Please try again.');
            }
        } catch (ex) {
            setErrorMsg('An error occurred while updating the user: ' + ex.message);
        }
    }

    const handleUserPackageChange = (sgpId) => {
        setPackageId(sgpId);
        const chosenSGP = selfGuidedData.filter((sgp) => sgp.id === sgpId);
        setTripId(chosenSGP[0].trip.id);
    };

    return (
        <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
            <div className='flex justify-between items-center h-20'>
                <Header
                    category=''
                    title={fromStatus === 'create' ? 'Add New Users' : `Edit User ${email}`}
                />
            </div>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '100%' }}>
                    <h3>Name: {firstName} {lastName}</h3>
                    <h3>Role: {role}</h3>
                    <h3>Friendly Id: {friendlyId}</h3>
                    <h3>Registration date: {createdAt}</h3>
                    <h3>Keep Me Updated: {keepMeUpdated ? 'True' : 'False'}</h3>
                    <h3>Last Login At: {lastLoginAt ? lastLoginAt : ''}</h3>
                    <label>
                        <input
                            type="checkbox"
                            checked={deleteActives}
                            onChange={() => setDeleteActives(!deleteActives)}
                        />
                        Delete active package and trip
                    </label>
                    <label>Self Guided Package:</label>
                    {!deleteActives && (
                        <select
                            className="InputBoxCss"
                            style={{ width: 600 }}
                            value={packageId || ''}
                            onChange={(e) => handleUserPackageChange(e.target.value)}
                        >
                            <option value="">Select Self Guide Package</option>
                            {selfGuidedData && selfGuidedData.map((sgp) => (
                                <option key={sgp.id} value={sgp.id}>
                                    {sgp.trip && sgp.trip.name ? `(ID ${sgp.friendly_id}) - ${sgp.name} - Trip: ${sgp.trip.name} (ID ${sgp.trip.friendly_id})` : 'Unnamed SGP'}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
            </div>
            {errorMsg && <div className="error-message">{errorMsg}</div>}
            {fromStatus === 'create' ? (
                <div
                    className='AddBtn'
                    onClick={() => {
                        createUserFun();
                    }}
                >
                    Add
                </div>
            ) : (
                <div
                    className='AddBtn'
                    onClick={() => {
                        editUserFun();
                    }}
                >
                    Update
                </div>
            )}
        </div>
    );
};

export default EditUser;
