import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import './NewMain.css';

import { BASE_URL, deleteCall, get } from "../../Services/Calls";
import { Header } from "../../components";
import CreateAddIcon from "../../data/createAddIcon.png";
import { tripsGrid } from "../../data/dummy";

const Trips = () => {

  const navigate = useNavigate();

  const FilterOptions = {
    type: 'Excel'
  };

  const selectionSettings = { checkboxOnly: true };
  const toolbarOptions = ["Delete", 'Search'];

  const [tripsData, setTripsData] = useState([]);
  const gridRef = useRef(null);

  const [selectedRecords, setSelectedRecords] = useState([]);

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const editOptions = { allowEditing: false, allowAdding: true, allowDeleting: true };

  useEffect(() => {
    getTrips()
  }, []);

  const getTrips = async () => {
    try {
      const response = await get(BASE_URL + "/trips?order=DESC&take=999");
      if (response && response.results && response.results.length > 0) {
        setTripsData(response.results);
      }
    }
    catch (ex) {
      alert(ex);
    }
  };

  const toolbarClick = args => {
    switch (args.item.text) {
      case 'Delete':
        args.cancel = true;
        setDeleteConfirm(true);
        break
      default:
        break
    }
  }

  const handleDelete = async () => {

    try {
      for (let i = 0; i < selectedRecords.length; i++) {
        const response = deleteCall(
          await BASE_URL + '/trips/' + selectedRecords[i].id
        )
        if (response) {

        }
      }
      setTimeout(() => {
        getTrips();
        setDeleteConfirm(false);
      }, 200);

    }
    catch (ex) {
      alert(ex);
    }
  }

  const rowSelected = () => {
    if (gridRef.current) {
      const tempSelectedrecords = gridRef.current.getSelectedRecords();
      setSelectedRecords(tempSelectedrecords);
    }
  }

  const rowDeselected = args => {
    let tempSelectedRecords = [...selectedRecords]
    for (let i = 0; i < tempSelectedRecords.length; i++) {
      if (args.data.length > 1) {
        for (let j = 0; j < args.data.length; j++) {
          if (tempSelectedRecords[i].id === args.data[j].id) {
            tempSelectedRecords.splice(i, 1);
          }
        }
      } else {
        if (tempSelectedRecords[i].id === args.data.id) {
          tempSelectedRecords.splice(i, 1);
        }
      }
    }
    setSelectedRecords(tempSelectedRecords);
  }

  return (
    <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
      <div className="flex justify-between items-center h-20">
        <Header category="" title="Trips" />
        <div style={{ width: 200 }} className="w-100 border flex justify-end items-center mb-8 createBtn" onClick={() => { navigate('/createTrip', { state: { from: 'create', data: {} } }) }}>
          <span>Add New Trip</span>
          <img src={CreateAddIcon} style={{ width: 15, height: 15 }} alt="" />
        </div>
      </div>
      <GridComponent
        dataSource={tripsData}
        editSettings={editOptions}
        toolbar={toolbarOptions}
        allowPaging
        allowSorting
        filterSettings={FilterOptions}
        selectionSettings={selectionSettings}
        allowFiltering={true}
        pageSettings={{ pageCount: 3, pageSize: 10 }}
        toolbarClick={toolbarClick}
        rowSelected={rowSelected}
        rowDeselected={rowDeselected}
        ref={gridRef}
      >
        <ColumnsDirective>
          {tripsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Edit, Toolbar, Page, Filter]} />
      </GridComponent>
      {deleteConfirm && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: 400,
              height: 200,
              background: '#fff',
              borderRadius: 10,
              padding: '15px 20px'
            }}
          >
            <div
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                color: '#000',
                marginBottom: 15
              }}
            >
              Delete Alert
            </div>
            <div style={{ fontSize: 18, color: '#000', marginBottom: 10 }}>
              {selectedRecords.length > 0 ? "Are you sure you want to delete selected rows." : "Please select at-least one row."}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 20
              }}
            >
              <div
                className='confirmOkBtn'
                onClick={() => {
                  getTrips()
                  setDeleteConfirm(false)
                }}
              >
                {selectedRecords.length > 0 ? "Cancle" : "Ok"}
              </div>
              {selectedRecords.length > 0 &&
                <div
                  className='confirmOkBtn'
                  onClick={() => {
                    handleDelete()
                  }}
                >
                  Yes
                </div>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Trips;
