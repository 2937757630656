import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, post, put } from "../../Services/Calls";
import { notify } from "../../Services/Utils";
import { Header } from "../../components";
import './NewMain.css';

const CreateCurrency = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [currencyName, setCurrencyName] = useState('');
    const [description, setDescription] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [fromStatus, setFromStatus] = useState('');
    const [currencyId, setCurrencyId] = useState('');
    const [symbol, setSymbol] = useState('');

    useEffect(() => {
        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from);
            if (location.state.data && location.state.data.id) {
                setCurrencyName(location.state.data.name);
                setDescription(location.state.data.description);
                setSymbol(location.state.data.symbol);
                setCurrencyId(location.state.data.id);
            }
        }
        window.scrollTo(0, 0);
    }, []);

    async function createCurrencyFun() {
        if (currencyName == '' || currencyName == undefined) {
            setErrorMsg('Please Enter Currency Name.');
            return;
        }

        if (description == '' || description == undefined) {
            setErrorMsg('Please Enter Currency Description.');
            return;
        }

        if (symbol == '' || symbol == undefined) {
            setErrorMsg('Please Enter Currency Symbol.');
            return;
        }

        try {
            let json = {
                "name": currencyName,
                "description": description,
                "symbol": symbol
            }
            const response = await post(BASE_URL + '/currencies', json);
            if (response) {
                notify('Currency created successfully');
                setCurrencyName("");
                setDescription("");
                setSymbol("");
                navigate('/currency');
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    async function editCurrencyFun() {
        if (currencyName == '' || currencyName == undefined) {
            setErrorMsg('Please Enter Currency Name.');
            return;
        }

        if (description == '' || description == undefined) {
            setErrorMsg('Please Enter Currency Description.');
            return;
        }

        if (symbol == '' || symbol == undefined) {
            setErrorMsg('Please Enter Currency Symbol.');
            return;
        }

        try {
            let json = {
                "name": currencyName,
                "description": description,
                "symbol": symbol
            }

            const response = await put(BASE_URL + '/currencies/' + currencyId, json);
            if (response) {
                notify('Currency updated successfully');
                setCurrencyName("");
                setDescription("");
                setSymbol("");
                setCurrencyId("");
                navigate('/currency');
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    return (
        <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
            <div className="flex justify-between items-center h-20">
                <Header category="" title={fromStatus == "create" ? "Add New Currency" : "Edit Currency"} />
            </div>
            <div className="countryFormMainDiv p-10">
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Name</span>
                        <input type="text" className="InputBoxCss" style={{ width: 350 }} value={currencyName} onChange={(e) => { setCurrencyName(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Symbol</span>
                        <input type="text" className="InputBoxCss" style={{ width: 350 }} value={symbol} onChange={(e) => { setSymbol(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, marginTop: 40 }}>
                    <span style={{ fontSize: 20, color: '#000' }}>Description</span>
                    <textarea aria-invalid="false" id="txtDescription" rows="5" class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss" style={{ width: 600 }} value={description} onChange={(e) => { setDescription(e.target.value) }} onFocus={() => (setErrorMsg(""))} />
                </div>

                {errorMsg != '' &&
                    <div style={{ textAlign: 'center', color: 'red', fontSize: 16, marginTop: 30, }}>
                        {errorMsg}
                    </div>
                }
                {fromStatus == "create" ?
                    <div className="AddBtn" onClick={() => { createCurrencyFun() }}>
                        Add
                    </div>
                    :
                    <div className="AddBtn" onClick={() => { editCurrencyFun() }}>
                        Edit
                    </div>
                }
            </div>
        </div>
    );
};

export default CreateCurrency;
