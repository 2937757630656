import React, { useEffect, useRef, useState } from "react";
import './NewMain.css'
import { BASE_URL, get, post, put } from "../../Services/Calls";
import { Header } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import BlueCheck from '../../data/blueCheck.png'
import SelectedCheck from '../../data/selectedCheck.png'
import UnCheck from '../../data/unCheck.png'
import "../../pages/NewPages/Map/MapBox.css";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import SimpleFileUpload from 'react-simple-file-upload'
import { addPoiDayGrid, addGpxsDayGrid } from "../../data/dummy";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Toolbar,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DropDownArrow from '../../data/rightArrow.png';
import { mapboxglAccessToken, notify, SimpleFileUploadAPIKey } from "../../Services/Utils";

mapboxgl.accessToken = mapboxglAccessToken;


const CreateDay = () => {

  const navigate = useNavigate()
  const location = useLocation();

  const gridRef = useRef(null);
  const gridGpxRef = useRef(null);
  const toolbarOptions = ['Search'];
  const FilterOptions = {
    type: 'Excel'
  };
  const editOptions = {
    allowEditing: false,
    allowAdding: true,
    allowDeleting: true
  }
  const [dayName, setDayName] = useState('');
  const [overnight, setOvernight] = useState('');
  const [imageName, setImageName] = useState('');
  const [description, setDescription] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [fromStatus, setFromStatus] = useState('');
  const [dayId, setDayId] = useState('');
  const [friendlyId, setFriendlyId] = useState('');
  const [poiSort, setPoiSort] = useState('');
  const [showImagePopUp, setShowImagePopUp] = useState(false);
  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [direction, setDirection] = useState("ltr");  // Initial direction is left-to-right

  const [dayStartPoint, setDayStartPoint] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [country, setCountry] = useState({});
  const [dayEndPoint, setDayEndPoint] = useState('');
  const [personalized, setPersonalized] = useState(true);
  const [poiData, setPoiData] = useState([])
  const [gpxsData, setGpxsData] = useState([])
  const poiDataRef = useRef([])
  const gpxsDataRef = useRef([])
  const [selectedRecords, setSelectedRecords] = useState([])
  const selectedRecordsRef = useRef([])
  const [poiIdArray, setPoiIdArray] = useState([]);
  const [gpxsIdArray, setGpxsIdArray] = useState([]);
  const gpxsIdArrayRef = useRef([]);
  const poiIdArrayRef = useRef([]);

  const [map, setMap] = useState(null);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [mapMode, setMapMode] = useState('1');

  const mapRef = useRef(null);
  const mapRef2 = useRef(null);
  const markerRef = useRef(null);
  const marker2Ref = useRef(null);

  const toggleDirection = () => {
    setDirection((prevDirection) => (prevDirection === "ltr" ? "rtl" : "ltr"));
  };

  const getPoi = async () => {
    try {
      const response = await get(BASE_URL + '/pois')
      if (response && response.results && response.results.length > 0) {
        let responseData = response.results
        let json = { selected: false }
        response.results.forEach(item => {
          item.typeData = 'POI'
        })
        for (let i = 0; i < responseData.length; i++) {
          responseData[i] = { ...responseData[i], ...json }
        }
        // setPoiData(response.results)
        setPoiData(responseData)
        poiDataRef.current = responseData
      }
    }
    catch (ex) {
      alert(ex)
    }
  }
  const getGpxs = async () => {
    try {
      const response = await get(BASE_URL + '/gpx')
      if (response && response.results && response.results.length > 0) {
        response.results.forEach(item => {
          item.typeData = 'GPX'
        })
        let responseData = response.results
        let json = { selected: false }
        for (let i = 0; i < responseData.length; i++) {
          responseData[i] = { ...responseData[i], ...json }
        }
        // setPoiData(response.results)
        setGpxsData(responseData)
        gpxsDataRef.current = responseData
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  const getCountries = async () => {
    try {
      const response = await get(BASE_URL + '/countries?order=ASC');
      if (response && response.length > 0) {
        setCountryData(response);
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  const getPoiEdit = async (tempIdArray) => {
    try {
      const response = await get(BASE_URL + "/pois");
      if (response && response.results && response.results.length > 0) {
        response.results.forEach(item => {
          item.typeData = 'POI'
        })
        let tempDaysData = response.results
        if (tempIdArray && tempIdArray.length > 0) {
          for (let i = 0; i < tempDaysData.length; i++) {
            let status = false
            for (let j = 0; j < tempIdArray.length; j++) {
              if (tempDaysData[i].id == tempIdArray[j]) {
                status = true
              }
            }
            if (status) {
              tempDaysData[i] = { ...tempDaysData[i], ...{ selected: true } }
            }
            else {
              tempDaysData[i] = { ...tempDaysData[i], ...{ selected: false } }
            }
          }
        }
        setPoiData(tempDaysData)
        poiDataRef.current = tempDaysData
        setPoiIdArray(tempIdArray)
        poiIdArrayRef.current = tempIdArray
      }
    }
    catch (ex) {
      alert(ex)
    }
  };

  const getGpxsEdit = async (tempIdArray) => {
    try {
      const response = await get(BASE_URL + "/gpx");
      if (response && response.results && response.results.length > 0) {
        response.results.forEach(item => {
          item.typeData = 'GPX'
        })
        let tempDaysData = response.results
        if (tempIdArray && tempIdArray.length > 0) {
          for (let i = 0; i < tempDaysData.length; i++) {
            let status = false
            for (let j = 0; j < tempIdArray.length; j++) {
              if (tempDaysData[i].id == tempIdArray[j]) {
                status = true
              }
            }
            if (status) {
              tempDaysData[i] = { ...tempDaysData[i], ...{ selected: true } }
            }
            else {
              tempDaysData[i] = { ...tempDaysData[i], ...{ selected: false } }
            }
          }
        }
        setGpxsData(tempDaysData)
        gpxsDataRef.current = tempDaysData
        setGpxsIdArray(tempIdArray)
        gpxsIdArrayRef.current = tempIdArray
      }
    }
    catch (ex) {
      alert(ex)
    }
  };

  useEffect(() => {
    // Create a map instance
    mapRef.current = new mapboxgl.Map({
      container: 'map', // Specify the container ID
      style: mapStyle, // Specify the map style
      center: [32.00, 35.00], // Specify the starting center
      zoom: 6, // Specify the starting zoom
    });
    mapRef.current.addControl(new mapboxgl.NavigationControl());

    mapRef.current.on('load', () => {
      setMap(mapRef.current);
    });


    mapRef.current.on('style.load', function () {
      mapRef.current.on('click', function (e) {
        var coordinates = e.lngLat;
        new mapboxgl.Popup()
        if (markerRef.current != null) {
          markerRef.current.remove()
        }
        markerRef.current = new mapboxgl.Marker()
          .setLngLat(coordinates)
          .addTo(mapRef.current);
        setDayStartPoint(coordinates.lat + "," + coordinates.lng)
        mapRef.current.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 10 });
      });
    });

    // Clean up the map instance when the component unmounts
    return () => mapRef.current.remove();
  }, [mapStyle, mapMode]); // The empty dependency array ensures this useEffect runs only once

  useEffect(() => {
    // Create a map instance
    mapRef2.current = new mapboxgl.Map({
      container: 'map2', // Specify the container ID
      style: mapStyle, // Specify the map style
      center: [32.00, 35.00], // Specify the starting center
      zoom: 10, // Specify the starting zoom
    });
    mapRef2.current.addControl(new mapboxgl.NavigationControl());
    mapRef2.current.on('load', () => {
      setMap(mapRef2.current);
    });

    mapRef2.current.on('style.load', function () {
      mapRef2.current.on('click', function (e) {
        var coordinates = e.lngLat;
        new mapboxgl.Popup()
        if (marker2Ref.current != null) {
          marker2Ref.current.remove()
        }
        marker2Ref.current = new mapboxgl.Marker()
          .setLngLat(coordinates)
          // .setHTML('' + coordinates)
          .addTo(mapRef2.current);
        setDayEndPoint(coordinates.lat + "," + coordinates.lng);
        mapRef2.current.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 10 });
      });
    });
    // Clean up the map instance when the component unmounts
    return () => mapRef2.current.remove();
  }, [mapStyle, mapMode]); // The empty dependency array ensures this useEffect runs only once

  useEffect(() => {
    (async () => {
      await getCountries();
    })();

    if (location && location.state && location.state.from) {
      setFromStatus(location.state.from);
      if (location.state.from == 'create') {
        getPoi();
        getGpxs();
      }
      if (location.state.data && location.state.data.id) {
        setDayName(location.state.data.name);
        setOvernight(location.state.data.overnight);
        setDescription(location.state.data.description);
        setImageName(location.state.data.imageURL);
        setCountry(location.state.data.country);
        setDayStartPoint(location.state.data.start_latitude + "," + location.state.data.start_longitude)
        setDayEndPoint(location.state.data.end_latitude + "," + location.state.data.end_longitude)
        setDayId(location.state.data.id);
        setFriendlyId(location.state.data.friendly_id);
        setPersonalized(location.state.data.personalized);
        setPoiSort(location.state.data.poi_set_sort);
        if (location.state.data.poi_set && location.state.data.poi_set.length > 0) {
          location.state.data.poi_set.forEach(item => {
            item.typeData = 'POI';
          })
        }
        if (location.state.data.gpx_set && location.state.data.gpx_set.length > 0) {
          location.state.data.gpx_set.forEach(item => {
            item.typeData = 'GPX';
          })
        }
        const sort = location.state.data.poi_set_sort;
        const arrDataSelected = [...location.state.data.poi_set, ...location.state.data.gpx_set];
        if (sort !== null && sort !== undefined && sort !== "") {
          const arrDataSelected = [...location.state.data.poi_set, ...location.state.data.gpx_set];
          const sortedData = sort.split(',').map(id => arrDataSelected.find(item => item.id === id));
          setSelectedRecords(sortedData);
          selectedRecordsRef.current = sortedData;
        } else {
          setSelectedRecords(arrDataSelected);
          selectedRecordsRef.current = arrDataSelected;
        }
        let tempPois = location.state.data.poi_set
        let tempGpxs = location.state.data.gpx_set
        let tempIdArrayPois = []
        let tempIdArrayGpxs = []
        for (let i = 0; i < tempPois.length; i++) {
          tempIdArrayPois.push(tempPois[i].id);
        }
        for (let i = 0; i < tempGpxs.length; i++) {
          tempIdArrayGpxs.push(tempGpxs[i].id);
        }
        setPoiIdArray(tempIdArrayPois);
        poiIdArrayRef.current = tempIdArrayPois;
        getPoiEdit(tempIdArrayPois);

        setGpxsIdArray(tempIdArrayGpxs);
        gpxsIdArrayRef.current = tempIdArrayGpxs;
        getGpxsEdit(tempIdArrayGpxs);

        markerRef.current = new mapboxgl.Marker()
          .setLngLat([location.state.data.start_longitude, location.state.data.start_latitude])
          .addTo(mapRef.current);
        mapRef.current.flyTo({ center: [location.state.data.start_longitude, location.state.data.start_latitude], zoom: 10 });

        marker2Ref.current = new mapboxgl.Marker()
          .setLngLat([location.state.data.end_longitude, location.state.data.end_latitude])
          .addTo(mapRef2.current);
        mapRef2.current.flyTo({ center: [location.state.data.end_longitude, location.state.data.end_latitude], zoom: 10 });
      }
    }
    window.scrollTo(0, 0);
  }, []);

  async function createDayFun() {
    let tempDayStartPoint = dayStartPoint.split(',')
    let tempDayEndPoint = dayEndPoint.split(',')
    if (dayName == '' || dayName == undefined) {
      setErrorMsg('Please Enter Day Name');
      return;
    }

    if (overnight == '' || overnight == undefined) {
      setErrorMsg('Please Enter Overnight value');
      return;
    }

    if (imageName && imageName != '') { }
    else {
      setErrorMsg('Please Upload Day Image');
      return;
    }

    if (description == '' || description == undefined) {
      setErrorMsg('Please Enter Day Description');
      return;
    }

    if (dayStartPoint == '' || dayStartPoint == undefined) {
      setErrorMsg('Please Select Day Start Point');
      return;
    }

    if (dayEndPoint == '' || dayEndPoint == undefined) {
      setErrorMsg('Please Select Day End Point');
      return;
    }

    try {
      let json = {
        "name": dayName,
        "overnight": overnight,
        "active": true,
        "description": description,
        "imageURL": imageName,
        "country": country.id,
        "start_latitude": parseFloat(tempDayStartPoint[0]),
        "start_longitude": parseFloat(tempDayStartPoint[1]),
        "end_latitude": parseFloat(tempDayEndPoint[0]),
        "end_longitude": parseFloat(tempDayEndPoint[1]),
        "poi_set": poiIdArray,
        "personalized": personalized
      };

      const response = await post(BASE_URL + '/days', json)
      if (response) {
        notify('Day created successfully');
        setDayName("");
        setOvernight("");
        setDayStartPoint("");
        setDayEndPoint("");
        setDescription("");
        setCountry({});
        setImageName("");
        navigate('/days');
      }
    }
    catch (ex) {
      alert(ex);
    }
  }

  async function editDayFun() {

    let tempDayStartPoint = dayStartPoint.split(',');
    let tempDayEndPoint = dayEndPoint.split(',');

    if (dayName == '' || dayName == undefined) {
      setErrorMsg('Please Enter Day Name');
      return;
    }

    if (overnight == '' || overnight == undefined) {
      setErrorMsg('Overnight missing - Please Enter Overnight');
      return;
    }

    if (imageName && imageName == '' || imageName == undefined) {
      setErrorMsg('Please Upload Country Image');
      return;
    }

    if (description == '' || description == undefined) {
      setErrorMsg('Please Enter Day Description');
      return;
    }

    if (dayStartPoint == '' || dayStartPoint == undefined) {
      setErrorMsg('Please Select Day Start Point');
      return;
    }

    if (dayEndPoint == '' || dayEndPoint == undefined) {
      setErrorMsg('Please Select Day End Point');
      return;
    }
    try {
      let json = {
        "name": dayName,
        "overnight": overnight,
        "active": true,
        "description": description,
        "imageURL": imageName,
        "country": country.id,
        "start_latitude": parseFloat(tempDayStartPoint[0]),
        "start_longitude": parseFloat(tempDayStartPoint[1]),
        "end_latitude": parseFloat(tempDayEndPoint[0]),
        "end_longitude": parseFloat(tempDayEndPoint[1]),
        "poi_set": selectedRecordsRef.current.map((item) => item.id),
        "personalized": personalized
      };

      const response = await put(BASE_URL + '/days/' + dayId, json);
      if (response) {
        notify(`Day ${friendlyId} updated successfully`);
        setDayName("");
        setOvernight("");
        setDayStartPoint("");
        setDayEndPoint("");
        setDescription("");
        setCountry({});
        setImageName("");
        setDayId("");
        navigate('/days');
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  function handleUpload(url) {
    setImageName(url)
    setShowImagePopUp(false)
    setErrorMsg("")
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Drop outside the list
    }
    const reorderedItems = Array.from(selectedRecordsRef.current);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setSelectedRecords(reorderedItems);
    selectedRecordsRef.current = reorderedItems;
    let tempIdArray = []
    for (let i = 0; i < reorderedItems.length; i++) {
      tempIdArray.push(reorderedItems[i].id);
    }
    setPoiIdArray(tempIdArray);
    poiIdArrayRef.current = tempIdArray;
  };

  // function selectRowFun(props) {
  //   if (props && props.selected) {
  //     let tempPoiData = [...poiDataRef.current]; // Crear una nueva instancia del array
  //     for (let i = 0; i < tempPoiData.length; i++) {
  //       if (props.id == tempPoiData[i].id) {
  //         tempPoiData[i].selected = false;
  //       }
  //     }

  //     setPoiData(tempPoiData);
  //     poiDataRef.current = tempPoiData;

  //     let tempSelectedRecord = [...selectedRecordsRef.current]
  //     for(let i=0;i<tempSelectedRecord.length;i++){
  //       if(props.id == tempSelectedRecord[i].id){
  //         tempSelectedRecord.splice(i, 1)
  //       }
  //     }
  //     setSelectedRecords(tempSelectedRecord)
  //     selectedRecordsRef.current = tempSelectedRecord
  //     let tempPoiIdArray = [...poiIdArrayRef.current]
  //     for(let i=0;i<tempPoiIdArray.length;i++){
  //       if(props.id == tempPoiIdArray[i]){
  //         tempPoiIdArray.splice(i, 1)
  //       }
  //     }
  //     setPoiIdArray(tempPoiIdArray)
  //     poiIdArrayRef.current = tempPoiIdArray
  //   }

  //   else {
  //     let tempPoiData = poiDataRef.current
  //     let tempSelectedRecord = [...selectedRecordsRef.current]
  //     let tempPoiIdArray = [...poiIdArrayRef.current]
  //     for (let i = 0; i < tempPoiData.length; i++) {
  //       if (props.id == tempPoiData[i].id) {
  //         tempPoiData[i].selected = true
  //         tempSelectedRecord = [...tempSelectedRecord,tempPoiData[i]]
  //         tempPoiIdArray = [...poiIdArrayRef.current,tempPoiData[i].id]
  //       }
  //     }
  //     setSelectedRecords(tempSelectedRecord)
  //     selectedRecordsRef.current  = tempSelectedRecord

  //     setPoiData(tempPoiData)
  //     poiDataRef.current = tempPoiData;
  //     setPoiIdArray(tempPoiIdArray)
  //     poiIdArrayRef.current = tempPoiIdArray
  //   }
  //   gridRef.current.refresh();
  // }

  // function selectRowFunGpxs(props) {
  //   if (props && props.selected) {
  //     let tempGpxsData = [...gpxsDataRef.current]; // Crear una nueva instancia del array
  //     for (let i = 0; i < tempGpxsData.length; i++) {
  //       if (props.id == tempGpxsData[i].id) {
  //         tempGpxsData[i].selected = false;
  //       }
  //     }

  //     setGpxsData(tempGpxsData);
  //     gpxsDataRef.current = tempGpxsData;

  //     let tempSelectedRecord = [...selectedRecordsRef.current]
  //     for(let i=0;i<tempSelectedRecord.length;i++){
  //       if(props.id == tempSelectedRecord[i].id){
  //         tempSelectedRecord.splice(i, 1)
  //       }
  //     }
  //     setSelectedRecords(tempSelectedRecord)
  //     selectedRecordsRef.current = tempSelectedRecord
  //     let tempGpxsIdArray = [...gpxsIdArrayRef.current]
  //     for(let i=0;i<tempGpxsIdArray.length;i++){
  //       if(props.id == tempGpxsIdArray[i]){
  //         tempGpxsIdArray.splice(i, 1)
  //       }
  //     }
  //     setPoiIdArray(tempGpxsIdArray)
  //     gpxsIdArrayRef.current = tempGpxsIdArray
  //   }

  //   else {
  //     let tempGpxsData = gpxsDataRef.current
  //     let tempSelectedRecord = [...selectedRecordsRef.current]    

  //     let tempGpxsIdArray = [...gpxsIdArrayRef.current]
  //     for (let i = 0; i < tempGpxsData.length; i++) {
  //       if (props.id == tempGpxsData[i].id) {
  //         tempGpxsData[i].selected = true
  //         tempSelectedRecord = [...tempSelectedRecord,tempGpxsData[i]]
  //         tempGpxsIdArray = [...gpxsIdArrayRef.current,tempGpxsData[i].id]
  //       }
  //     }
  //     setSelectedRecords(tempSelectedRecord)
  //     selectedRecordsRef.current  = tempSelectedRecord

  //     setGpxsData(tempGpxsData)
  //     gpxsDataRef.current = tempGpxsData;
  //     setPoiIdArray(tempGpxsIdArray)
  //     gpxsIdArrayRef.current = tempGpxsIdArray
  //   }
  //   gridGpxRef.current.refresh();
  // }

  function selectRowFun(props) {
    if (props && props.selected) {
      let tempPoiData = [...poiDataRef.current];
      for (let i = 0; i < tempPoiData.length; i++) {
        if (props.id == tempPoiData[i].id) {
          tempPoiData[i].selected = false;
        }
      }

      setPoiData(tempPoiData);
      poiDataRef.current = tempPoiData;

      let tempSelectedRecord = [...selectedRecordsRef.current];
      for (let i = 0; i < tempSelectedRecord.length; i++) {
        if (props.id == tempSelectedRecord[i].id) {
          tempSelectedRecord.splice(i, 1);
        }
      }
      setSelectedRecords(tempSelectedRecord);
      selectedRecordsRef.current = tempSelectedRecord;
      let tempPoiIdArray = [...poiIdArrayRef.current];
      for (let i = 0; i < tempPoiIdArray.length; i++) {
        if (props.id == tempPoiIdArray[i]) {
          tempPoiIdArray.splice(i, 1);
        }
      }
      setPoiIdArray(tempPoiIdArray);
      poiIdArrayRef.current = tempPoiIdArray;
    } else {
      let tempPoiData = poiDataRef.current;
      let tempSelectedRecord = [...selectedRecordsRef.current];
      let tempPoiIdArray = [...poiIdArrayRef.current];
      for (let i = 0; i < tempPoiData.length; i++) {
        if (props.id == tempPoiData[i].id) {
          tempPoiData[i].selected = true;
          tempSelectedRecord = [...tempSelectedRecord, tempPoiData[i]];
          tempPoiIdArray = [...selectedRecordsRef.current.map(record => record.id), props.id];
        }
      }
      setSelectedRecords(tempSelectedRecord);
      selectedRecordsRef.current = tempSelectedRecord;
      setPoiData(tempPoiData);
      poiDataRef.current = tempPoiData;
      setPoiIdArray(tempPoiIdArray);
      poiIdArrayRef.current = tempPoiIdArray;
    }
    gridRef.current.refresh();
  }

  function selectRowFunGpxs(props) {
    if (props && props.selected) {
      let tempGpxsData = [...gpxsDataRef.current];
      for (let i = 0; i < tempGpxsData.length; i++) {
        if (props.id == tempGpxsData[i].id) {
          tempGpxsData[i].selected = false;
        }
      }

      setGpxsData(tempGpxsData);
      gpxsDataRef.current = tempGpxsData;

      let tempSelectedRecord = [...selectedRecordsRef.current];
      for (let i = 0; i < tempSelectedRecord.length; i++) {
        if (props.id == tempSelectedRecord[i].id) {
          tempSelectedRecord.splice(i, 1);
        }
      }
      setSelectedRecords(tempSelectedRecord);
      selectedRecordsRef.current = tempSelectedRecord;

      let tempGpxsIdArray = [...gpxsIdArrayRef.current];
      for (let i = 0; i < tempGpxsIdArray.length; i++) {
        if (props.id == tempGpxsIdArray[i]) {
          tempGpxsIdArray.splice(i, 1);
        }
      }
      setPoiIdArray(tempGpxsIdArray);
      gpxsIdArrayRef.current = tempGpxsIdArray;
    } else {
      let tempGpxsData = gpxsDataRef.current;
      let tempSelectedRecord = [...selectedRecordsRef.current];
      let tempGpxsIdArray = [...gpxsIdArrayRef.current];
      for (let i = 0; i < tempGpxsData.length; i++) {
        if (props.id == tempGpxsData[i].id) {
          tempGpxsData[i].selected = true;
          tempSelectedRecord = [...tempSelectedRecord, tempGpxsData[i]];
          tempGpxsIdArray = [...selectedRecordsRef.current.map(record => record.id), props.id];
        }
      }
      setSelectedRecords(tempSelectedRecord);
      selectedRecordsRef.current = tempSelectedRecord;
      setGpxsData(tempGpxsData);
      gpxsDataRef.current = tempGpxsData;
      setPoiIdArray(tempGpxsIdArray);
      gpxsIdArrayRef.current = tempGpxsIdArray;
    }
    gridGpxRef.current.refresh();
  }

  const selectTableRows = (props) => (
    <div>
      {props && props.selected ?
        <img src={SelectedCheck} style={{ width: 18, height: 18 }} onClick={() => { selectRowFun(props) }} />
        :
        <img src={UnCheck} style={{ width: 18, height: 18 }} onClick={() => { selectRowFun(props) }} />
      }
    </div>
  );

  const selectTableRowsGpxs = (props) => (
    <div>
      {props && props.selected ?
        <img src={SelectedCheck} style={{ width: 18, height: 18 }} onClick={() => { selectRowFunGpxs(props) }} />
        :
        <img src={UnCheck} style={{ width: 18, height: 18 }} onClick={() => { selectRowFunGpxs(props) }} />
      }
    </div>
  );

  return (
    <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
      <div className="flex justify-between items-center h-20">
        <Header category="" title={fromStatus == "create" ? "Add New Day" : `Edit Day ${friendlyId}`} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }} className="mb-10 mr-20">
          {personalized ?
            <img src={BlueCheck} style={{ height: 25, width: 25, cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} />
            :
            <div style={{ borderRadius: 4, height: 25, width: 25, border: '2px solid #2D96F3', cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} >
            </div>
          }
          <div style={{ fontSize: 22, fontWeight: 'bold' }}>Personalized</div>
          <button
            onClick={toggleDirection}
            style={{
              backgroundColor: "#007BFF", // Blue color
              color: "#fff", // White text
              border: "none",
              borderRadius: "8px", // Rounded corners
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Shadow effect
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#007BFF")}
          >
            כיוון תצוגה ({direction === 'ltr' ? 'אנגלית' : 'עברית'})
          </button>
        </div>
      </div>
      <div className="countryFormMainDiv p-10">
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100 }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
              <span style={{ fontSize: 20, color: '#000', fontWeight: 'bold' }}>Name</span>
              <input type="text" className="InputBoxCss" dir={direction} style={{ width: 650 }} value={dayName} onChange={(e) => { setDayName(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, marginTop: 40 }}>
              <span style={{ fontSize: 20, color: '#000', fontWeight: 'bold' }}>Description</span>
              <textarea aria-invalid="false" id="txtDescription" dir={direction} rows="5" class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss" style={{ width: 600 }} value={description} onChange={(e) => { setDescription(e.target.value) }} onFocus={() => (setErrorMsg(""))} />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
            <span style={{ fontSize: 20, color: '#000' }}>Image</span>
            {imageName && imageName != "" ?
              <>
                <img src={imageName} style={{ width: 250, height: 150, borderRadius: 10 }} alt="" />
                <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                  Change Image
                </div>
              </>
              :
              <SimpleFileUpload
                apiKey={SimpleFileUploadAPIKey}
                onSuccess={handleUpload}
                width="350"
                height="150"
              />
            }
          </div>
        </div>

        <div
          style={{
            width: '60%'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, marginTop: 40 }}>
            <span style={{ fontSize: 20, color: '#000', fontWeight: 'bold' }}>
              Country
            </span>

            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
              {country && country.id ?
                <span style={{ color: '#000' }}>{country.name}</span>
                :
                <span style={{ color: 'gray' }}>Select Country</span>
              }
              <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
            </div>
            {showCountrySelect &&
              <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                <div className="dropDownDiv">
                  {countryData && countryData.length > 0 && countryData.map((item, index) => (
                    <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                      {item.name}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            }
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
          <span style={{ fontSize: 20, color: '#000', fontWeight: 'bold' }}>Overnight</span>
          <input type="text" className="InputBoxCss" style={{ width: 300 }} value={overnight} onChange={(e) => { setOvernight(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
        </div>

        <div className="selectLatLongDiv">
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
              <span style={{ fontSize: 20, color: '#000', fontWeight: 'bold' }}>Day Start Location</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: 10,
                  width: '60%'
                }}
              >
                <input
                  type='text'
                  className='InputBoxCss'
                  style={{ width: 350 }}
                  placeholder='32.00, 34.00'
                  value={dayStartPoint}
                  onChange={e => {
                    let latlng = e.target.value;
                    if (e.target.value === '') {
                      setDayStartPoint("")
                      if (markerRef.current != null) {
                        markerRef.current.remove();
                      }
                    } else {
                      setDayStartPoint("")
                      if (markerRef.current != null) {
                        markerRef.current.remove();
                      }
                    }
                    latlng = latlng.trim();
                    let originalLatLng = latlng;
                    latlng = latlng.split(',');
                    if (latlng.length === 2) {
                      setDayStartPoint(originalLatLng);
                      markerRef.current = new mapboxgl.Marker()
                        .setLngLat([latlng[1].trim(), latlng[0].trim()])
                        .addTo(mapRef.current);
                      mapRef.current.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 10 });
                    }
                  }}
                  onFocus={() => setErrorMsg('')}
                />
              </div>

            </div>
            <div className="outerMapContainer">
              <div id="map" style={{ width: '100%', height: '100%' }}></div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
              <span style={{ fontSize: 20, color: '#000', fontWeight: 'bold' }}>Day End Location</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: 10,
                  width: '60%'
                }}
              >
                <input
                  type='text'
                  className='InputBoxCss'
                  style={{ width: 350 }}
                  placeholder='32.00, 34.00'
                  value={dayEndPoint}
                  onChange={e => {
                    let latlng = e.target.value;
                    if (e.target.value === '') {
                      setDayEndPoint("");
                      if (marker2Ref.current != null) {
                        marker2Ref.current.remove();
                      }
                    } else {
                      setDayEndPoint("")
                      if (marker2Ref.current != null) {
                        marker2Ref.current.remove();
                      }
                    }
                    latlng = latlng.trim();
                    let originalLatLng = latlng;
                    latlng = latlng.split(',');
                    if (latlng.length === 2) {
                      setDayEndPoint(originalLatLng);
                      marker2Ref.current = new mapboxgl.Marker()
                        .setLngLat([latlng[1].trim(), latlng[0].trim()])
                        .addTo(mapRef2.current);
                      mapRef2.current.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 10 });
                    }
                  }}
                  onFocus={() => setErrorMsg('')}
                />
              </div>

            </div>
            <div className="outerMapContainer">
              <div id="map2" style={{ width: '100%', height: '100%' }}></div>
            </div>
          </div>

        </div>

        <div style={{ marginTop: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50 }}>
            <div style={{ width: '50%', }}>
              <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
                Select POIs:
              </div>

              <GridComponent
                dataSource={poiData}
                enableHover={false}
                allowPaging
                pageSettings={{ pageCount: 3, pageSize: 10 }}
                // selectionSettings={selectionsettings}
                // toolbarClick={toolbarClick}
                toolbar={toolbarOptions}
                editSettings={editOptions}
                allowSorting
                filterSettings={FilterOptions} allowFiltering={true}
                // rowSelected={rowSelected}
                // rowDeselected={rowDeselected}
                ref={gridRef}
                style={{ width: '100%' }}
              >
                <ColumnsDirective>
                  <ColumnDirective template={selectTableRows} headerText="Select" width="80" />
                  {addPoiDayGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject services={[Page, Toolbar, Filter]} />
              </GridComponent>


            </div>

            <div style={{ width: '50%', }}>
              <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
                Order Selected POIs and GPXs (Drag to order):
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div style={{ minHeight: selectedRecords && selectedRecords.length > 0 ? 'auto' : 150, width: '100%', border: '1px solid #e0e0e0', }}>
                        {selectedRecordsRef && selectedRecordsRef.current && selectedRecordsRef.current.length > 0 && selectedRecordsRef.current[0] ?
                          <div>
                            {selectedRecordsRef && selectedRecordsRef.current.map((item, index) => (

                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ padding: '15px 15px', borderBottom: snapshot.isDragging ? '1px solid blue' : '1px solid #e0e0e0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 40, fontSize: 13, cursor: 'default' }}>
                                      <div style={{ width: 15, height: 15, border: '2.5px solid black', cursor: 'move' }}>
                                      </div>

                                      <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.friendly_id || ""}
                                      </div>
                                      <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.name || ""}
                                      </div>
                                      <div style={{ width: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.country ? item.country.name || "" : ""}
                                      </div>
                                      <div style={{ width: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.typeData ? item.typeData || "" : ""}
                                      </div>
                                    </div>

                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          :
                          <div style={{ height: 150, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, color: '#908d8d' }}>
                            No Data
                          </div>
                        }
                      </div>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>


        </div>
        <div style={{ marginTop: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50 }}>
            <div style={{ width: '50%', }}>
              <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
                Select GPXs:
              </div>

              <GridComponent
                dataSource={gpxsData}
                enableHover={false}
                allowPaging
                pageSettings={{ pageCount: 3, pageSize: 10 }}
                // selectionSettings={selectionsettings}
                // toolbarClick={toolbarClick}
                toolbar={toolbarOptions}
                editSettings={editOptions}
                allowSorting
                filterSettings={FilterOptions} allowFiltering={true}
                // rowSelected={rowSelected}
                // rowDeselected={rowDeselected}
                ref={gridGpxRef}
                style={{ width: '100%' }}
              >
                <ColumnsDirective>
                  <ColumnDirective template={selectTableRowsGpxs} headerText="Select" width="80" />
                  {addGpxsDayGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject services={[Page, Toolbar, Filter]} />
              </GridComponent>
            </div>

            <div style={{ width: '50%', }}>
            </div>
          </div>
        </div>

        {errorMsg != '' &&
          <div style={{ textAlign: 'center', color: 'red', fontSize: 16, marginTop: 30, }}>
            {errorMsg}
          </div>
        }
        {fromStatus == "create" ?
          <div className="AddBtn" onClick={() => { createDayFun() }}>
            Add
          </div>
          :
          <div className="AddBtn" onClick={() => { editDayFun() }}>
            Update day {friendlyId}
          </div>
        }

      </div>

      {showImagePopUp && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => { setShowImagePopUp(false) }}
        >
          <div
            style={{
              background: '#fff',
              borderRadius: 10,
              padding: '15px'
            }}
          >
            <SimpleFileUpload
              apiKey={SimpleFileUploadAPIKey}
              onSuccess={handleUpload}
              width="350"
              height="150"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateDay;
