import React, { useEffect, useState } from "react";
import './NewMain.css'
import { BASE_URL, post, put } from "../../Services/Calls";
import { Header } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleFileUpload from 'react-simple-file-upload'
import { ToastContainer, toast } from 'react-toastify';
import CrossIcon from '../../data/close.png'
import { notify, SimpleFileUploadAPIKey } from "../../Services/Utils";

const CreateCountry = () => {

    const navigate = useNavigate()
    const location = useLocation();

    const [countryName, setCountryName] = useState('');
    const [imageName, setImageName] = useState('');
    const [flagImageName, setFlagImageName] = useState('');
    const [travelerInformationEnglish, setTravelerInformationEnglish] = useState('');
    const [travelerInformationHebrew, setTravelerInformationHebrew] = useState('');
    const [description, setDescription] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [fromStatus, setFromStatus] = useState('');
    const [countryId, setCountryId] = useState('');
    const [showImagePopUp, setShowImagePopUp] = useState(false);
    const [showFlagImagePopUp, setShowFlagImagePopUp] = useState(false);
    const [showFile1PopUp, setShowFile1PopUp] = useState(false);
    const [showFile2PopUp, setShowFile2PopUp] = useState(false);

    useEffect(() => {
        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from)
            if (location.state.data) {
                setCountryName(location.state.data.name)
                setDescription(location.state.data.description)
                setImageName(location.state.data.imageURL)
                setFlagImageName(location.state.data.flagURL)
                setCountryId(location.state.data.id)
                setTravelerInformationEnglish(location.state.data.traveler_information_english)
                setTravelerInformationHebrew(location.state.data.traveler_information_hebrew)
            }
        }
        window.scrollTo(0, 0);
    }, []);


    async function createCountryFun() {
        if (countryName && countryName == '' || countryName == undefined) {
            setErrorMsg('Please Enter Country Name.')
            return;
        }
        if (imageName && imageName == '' || imageName == undefined) {
            setErrorMsg('Please Upload Country Image.')
            return;
        }
        if (flagImageName && flagImageName == '' || flagImageName == undefined) {
            setErrorMsg('Please Upload Country Flag Image.')
            return;
        }
        if (description && description == '' || description == undefined) {
            setErrorMsg('Please Enter Description.')
            return;
        }
        try {
            let json = {
                "name": countryName,
                "active": true,
                "description": description,
                "imageURL": imageName,
                "flagURL": flagImageName,
                "traveler_information_english": travelerInformationEnglish != "" ? travelerInformationEnglish : null,
                "traveler_information_hebrew": travelerInformationHebrew != "" ? travelerInformationHebrew : null,
            }
            const response = await post(BASE_URL + '/countries', json)
            if (response) {
                notify('Country created successfully');
                navigate('/countries');
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    async function editCountryFun() {
        if (countryName && countryName == '' || countryName == undefined) {
            setErrorMsg('Please Enter Country Name.')
            return;
        }
        if (imageName && imageName == '' || imageName == undefined) {
            setErrorMsg('Please Upload Country Image.')
            return;
        }
        if (flagImageName && flagImageName == '' || flagImageName == undefined) {
            setErrorMsg('Please Upload Country Flag Image.')
            return;
        }
        if (description && description == '' || description == undefined) {
            setErrorMsg('Please Enter Description.')
            return;
        }
        try {
            let json = {
                "name": countryName,
                "active": true,
                "description": description,
                "imageURL": imageName,
                "flagURL": flagImageName,
                "traveler_information_english": travelerInformationEnglish != "" ? travelerInformationEnglish : null,
                "traveler_information_hebrew": travelerInformationHebrew != "" ? travelerInformationHebrew : null,
            }
            const response = await put(BASE_URL + '/countries/' + countryId, json)
            if (response) {
                notify('Country updated successfully');
                navigate('/countries');
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    function handleUpload(url) {
        setImageName(url)
        setShowImagePopUp(false)
        setErrorMsg("")
    }

    function handleUploadFlagImage(url) {
        setFlagImageName(url)
        setShowFlagImagePopUp(false)
        setErrorMsg("")
    }

    function handleUploadFile1(url) {
        setTravelerInformationEnglish(url)
        setShowFile1PopUp(false)
        setErrorMsg("")
    }

    function handleUploadFile2(url) {
        setTravelerInformationHebrew(url)
        setShowFile2PopUp(false)
        setErrorMsg("")
    }

    function splitItem(item) {
        const array = item.split("/");
        const lastElement = array.pop();
        return lastElement;
    }

    return (

        <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
            <div className="flex justify-between items-center h-20">
                <Header category="" title={fromStatus == "create" ? "Create Country" : "Edit Country"} />
            </div>
            <div className="countryFormMainDiv p-10">
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 100 }}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
                            <span style={{ fontSize: 20, color: '#000' }}>Name</span>
                            <input type="text" className="InputBoxCss" style={{ width: 350 }} value={countryName} onChange={(e) => { setCountryName(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, marginTop: 40 }}>
                            <span style={{ fontSize: 20, color: '#000' }}>Description</span>
                            <textarea aria-invalid="false" id="txtDescription" rows="5" className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss" style={{ width: 600 }} value={description} onChange={(e) => { setDescription(e.target.value) }} onFocus={() => (setErrorMsg(""))} />
                        </div>
                    </div>

                </div>


                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: 40 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '100%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Image</span>
                        {imageName && imageName != "" ?
                            <>
                                <img src={imageName} style={{ width: 250, height: 150, borderRadius: 10 }} alt="" />
                                <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                                    Change Image
                                </div>
                            </>
                            :
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleUpload}
                                width="350"
                            />
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '100%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Flag Image</span>
                        {flagImageName && flagImageName != "" ?
                            <>
                                <img src={flagImageName} style={{ width: 200, height: 150, borderRadius: 10 }} alt="" />
                                <div className="changeImg" style={{ width: 200 }} onClick={() => { setShowFlagImagePopUp(true) }}>
                                    Change Flag Image
                                </div>
                            </>
                            :
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleUploadFlagImage}
                                width="350"
                            />
                        }
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 0, marginTop: 40 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '100%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Traveler Information English</span>

                        {travelerInformationEnglish && travelerInformationEnglish != "" ?
                            <>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 40, marginTop: 30, marginBottom: 30 }}>
                                    <div className="voucherItem">
                                        <a href={travelerInformationEnglish} target="blank" >{splitItem(travelerInformationEnglish)}</a>
                                    </div>
                                    <img src={CrossIcon} style={{ height: 10, width: 10, cursor: 'pointer' }} onClick={() => { setTravelerInformationEnglish("") }} />
                                </div>
                                <div className="changeImg" onClick={() => { setShowFile1PopUp(true) }}>
                                    Change File
                                </div>
                            </>
                            :
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleUploadFile1}
                                accepted=".pdf, .doc, .docx, .txt"
                                width="350"
                            />
                        }
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '100%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Traveler Information Hebrew</span>

                        {travelerInformationHebrew && travelerInformationHebrew != "" ?
                            <>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 40, marginTop: 30, marginBottom: 30 }}>
                                    <div className="voucherItem">
                                        <a href={travelerInformationHebrew} target="blank" >{splitItem(travelerInformationHebrew)}</a>
                                    </div>
                                    <img src={CrossIcon} style={{ height: 10, width: 10, cursor: 'pointer' }} onClick={() => { setTravelerInformationHebrew("") }} />
                                </div>
                                <div className="changeImg" onClick={() => { setShowFile2PopUp(true) }}>
                                    Change File
                                </div>
                            </>

                            :
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleUploadFile2}
                                accepted=".pdf, .doc, .docx, .txt"
                                width="350"
                            />
                        }
                    </div>
                </div>


                {errorMsg != '' &&
                    <div style={{ textAlign: 'center', color: 'red', fontSize: 16, marginTop: 30, }}>
                        {errorMsg}
                    </div>
                }
                {fromStatus == "create" ?
                    <div className="AddBtn" onClick={() => { createCountryFun() }}>
                        Add
                    </div>
                    :
                    <div className="AddBtn" onClick={() => { editCountryFun() }}>
                        Edit
                    </div>
                }

            </div>

            {showImagePopUp && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => { setShowImagePopUp(false) }}
                >
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: 10,
                            padding: '15px'
                        }}
                    >
                        <SimpleFileUpload
                            apiKey={SimpleFileUploadAPIKey}
                            onSuccess={handleUpload}
                            width="350"
                        // onDrop={handleOnDrop}
                        />
                    </div>
                </div>
            )}
            {showFlagImagePopUp && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => { setShowFlagImagePopUp(false) }}
                >
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: 10,
                            padding: '15px'
                        }}
                    >
                        <SimpleFileUpload
                            apiKey={SimpleFileUploadAPIKey}
                            onSuccess={handleUploadFlagImage}
                            width="350"
                        />
                    </div>
                </div>
            )}
            {showFile1PopUp && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => { setShowFile1PopUp(false) }}
                >
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: 10,
                            padding: '15px'
                        }}
                    >
                        <SimpleFileUpload
                            apiKey={SimpleFileUploadAPIKey}
                            onSuccess={handleUploadFile1}
                            accepted=".pdf, .doc, .docx, .txt"
                            width="350"
                        />
                    </div>
                </div>
            )}
            {showFile2PopUp && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => { setShowFile2PopUp(false) }}
                >
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: 10,
                            padding: '15px'
                        }}
                    >
                        <SimpleFileUpload
                            apiKey={SimpleFileUploadAPIKey}
                            onSuccess={handleUploadFile2}
                            accepted=".pdf, .doc, .docx, .txt"
                            width="350"
                        />
                    </div>
                </div>
            )}

        </div>


    );
};

export default CreateCountry;
