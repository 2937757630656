import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../components';
import '../../pages/NewPages/Map/MapBox.css';
import { BASE_URL, get, post, put } from '../../Services/Calls';
import './NewMain.css';

import { mapboxglAccessToken, notify } from '../../Services/Utils';


mapboxgl.accessToken = mapboxglAccessToken;

const CreateAddons = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [errorMsg, setErrorMsg] = useState('');
    const [fromStatus, setFromStatus] = useState('');

    const [addons, setAddons] = useState([{ name: "", language: "", languageId: "" }]);
    const [title, setTitle] = useState('');
    const [addonId, setAddonId] = useState('');
    const [packageId, setPackageId] = useState('');

    const [languageData, setLanguageData] = useState([]);

    const [selfGuidedData, setSelfGuidedData] = useState([]);

    useEffect(() => {
        (async () => {
            await getLanguages();
            await getSelfGuided();
        })();
    }, [])

    const getSelfGuided = async () => {
        try {
            const response = await get(BASE_URL + '/self-guided-packages');
            if (response && response.results && response.results.length > 0) {
                setSelfGuidedData(response.results);
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    const getLanguages = async () => {
        try {
            const response = await get(BASE_URL + "/languages");
            if (response && response.length > 0) {
                setLanguageData(response);
            }
        }
        catch (ex) {
            alert(ex);
        }
    };

    useEffect(() => {
        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from);
            if (location.state.from == 'create') {

            }
            if (location.state.data && location.state.data.id) {
                const formattedAddons = location.state.data.name.map((addonData) => {
                    return {
                        name: addonData.text,
                        language: addonData.language,
                        languageId: addonData.languageId,
                    };
                });
                setPackageId(location.state.data.packageId);
                setAddons(formattedAddons);
                setTitle(location.state.data.title);
                setAddonId(location.state.data.id);
            }
        }
        window.scrollTo(0, 0);
    }, []);

    async function createAddonssFun() {
        try {
            const formattedAddons = addons.map(addon => {
                if (addon.name == '' || addon.name == undefined) {
                    setErrorMsg('Please Enter text');
                    return;
                }
                if (addon.language == '' || addon.language == undefined) {
                    setErrorMsg('Please select a language');
                    return;
                }

                return {
                    text: addon.name,
                    languageId: addon.languageId,
                    language: addon.language,
                };
            });
            let dataAddon = {
                title,
                packageId: packageId,
                texts: formattedAddons
            };
            const response = await post(BASE_URL + '/addon', dataAddon);
            if (response.status == 200) {
                notify('addon created successfully');
                navigate('/addons');
            }
            //   else{
            //  alert("please fill valid value in form or something went wrong, try again later.")
            //   }
        } catch (ex) {
            alert(ex)
        }
    }

    async function editTripFun() {
        // if (attractionName == '' || attractionName == undefined) {
        //     setErrorMsg('Please Enter Attraction Name.')
        //     return
        // }
        // if (about == '' || about == undefined) {
        //     setErrorMsg('Please Enter Attraction About.')
        //     return
        // }
        // if (imageName && imageName != '') { }
        // else {
        //     setErrorMsg('Please Upload Attraction Image.')
        //     return;
        // }

        try {
            const formattedAddons = addons.map(addon => {
                return {
                    text: addon.name,
                    languageId: addon.languageId,
                    language: addon.language,
                    packageId: addon.packageId
                };
            });
            let dataAddon = {
                title,
                packageId: packageId,
                texts: formattedAddons
            };
            const response = await put(BASE_URL + '/addon/update/' + addonId, dataAddon);
            if (response.status == 200) {
                notify('addon updated successfully');
                navigate('/addons');
            }
        } catch (ex) {
            alert(ex)
        }
    }

    const handleAddAddon = () => {
        setAddons([...addons, { name: "", language: "", languageId: "" }]);
    };

    const handleRemoveAddon = (index) => {
        const newAddons = [...addons];
        newAddons.splice(index, 1);
        setAddons(newAddons);
    };

    const handleAddonNameChange = (index, newName) => {
        const newAddons = [...addons];
        newAddons[index].name = newName;
        setAddons(newAddons);
    };
    const handleAddonTitle = (title) => {
        setTitle(title);
    };

    const handleAddonCheckedChange = (index, checked) => {
        const newAddons = [...addons];
        newAddons[index].checked = checked;
        setAddons(newAddons);
    };

    const handleAddonLanguageChange = (index, languageId) => {
        const newAddons = [...addons];
        newAddons[index].languageId = languageId;
        // Buscar el nombre del idioma correspondiente
        const language = languageData.find(lang => lang.id === languageId);
        if (language) {
            newAddons[index].language = language.name;
        }
        setAddons(newAddons);
    };

    const handleAddonPackageChange = (sgpId) => {
        setPackageId(sgpId)
    };

    return (
        <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
            <div className='flex justify-between items-center h-20'>
                <Header
                    category=''
                    title={fromStatus == 'create' ? 'Add New Addon' : `Edit Addon`}
                />
            </div>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
                <h1 style={{ fontSize: 22 }}>Title</h1>
                <input className="InputBoxCss" style={{ width: 250, height: 60, marginRight: '10px', fontSize: 22, marginBottom: 30 }} type="text" value={title} onChange={(e) => handleAddonTitle(e.target.value)} />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '100%' }}>
                    <h1 style={{ fontSize: 22 }}>Addons</h1>
                    {addons.map((addon, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <input className="InputBoxCss" style={{ height: 60, width: 250, marginRight: '10px', fontSize: 22 }} type="text" value={addon.name} onChange={(e) => handleAddonNameChange(index, e.target.value)} />
                            <select className="InputBoxCss" style={{ height: 60, width: 250, fontSize: 22 }} value={addon.languageId} onChange={(e) => handleAddonLanguageChange(index, e.target.value)}>
                                <option>Select Language</option>
                                {languageData.map((language) => (
                                    <option key={language.id} value={language.id}>
                                        {language.name}
                                    </option>
                                ))}
                            </select>

                            {index === addons.length - 1 && (
                                <button style={{ fontSize: 30 }} onClick={handleAddAddon}>+</button>
                            )}
                            {index !== addons.length - 1 && (
                                <button style={{ fontSize: 30 }} onClick={() => handleRemoveAddon(index)}>-</button>
                            )}
                        </div>
                    ))}
                    {/* <h3>Select Self Guide Package</h3>
                    <select
                        className="InputBoxCss"
                        style={{ width: 400 }}
                        value={packageId ? packageId : ''}
                        onChange={(e) => handleAddonPackageChange(e.target.value)}
                    >
                        <option value="">Select Package</option>
                        {selfGuidedData.map((sgp) => (
                            <option key={sgp.id} value={sgp.id}>
                                {sgp.trip.name}
                            </option>
                        ))}
                    </select> */}

                </div>
            </div>
            {fromStatus == 'create' ? (
                <div
                    className='AddBtn'
                    onClick={() => {
                        createAddonssFun()
                    }}
                >
                    Add
                </div>
            ) : (
                <div
                    className='AddBtn'
                    onClick={() => {
                        editTripFun()
                    }}
                >
                    Update
                </div>
            )}
        </div>
    )
}

export default CreateAddons;
