import React, { useEffect, useState } from "react";
import './NewMain.css'
import { BASE_URL, post, put } from "../../Services/Calls";
import { Header } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleFileUpload from 'react-simple-file-upload'
import { notify, SimpleFileUploadAPIKey } from "../../Services/Utils";

const CreateLanguage = () => {

    const navigate = useNavigate()
    const location = useLocation();

    const [languageName, setLanguageName] = useState('');
    const [imageName, setImageName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [fromStatus, setFromStatus] = useState('');
    const [languageId, setLanguageId] = useState('');
    const [showImagePopUp, setShowImagePopUp] = useState(false);

    useEffect(() => {
        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from)
            if (location.state.data) {
                setLanguageName(location.state.data.name)
                setImageName(location.state.data.image_flag)
                setLanguageId(location.state.data.id)
            }
        }
        window.scrollTo(0, 0);
    }, []);

    async function CreateLanguageFun() {
        if (languageName && languageName == '' || languageName == undefined) {
            setErrorMsg('Please Enter Language Name.');
            return;
        }
        if (imageName && imageName == '' || imageName == undefined) {
            setErrorMsg('Please Upload Flag Image.');
            return;
        }
        try {
            let json = {
                "name": languageName,
                "image_flag": imageName
            }
            const response = await post(BASE_URL + '/languages', json)
            if (response) {
                notify('Language created successfully');
                navigate('/language');
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    async function editLanguageFun() {
        if (languageName && languageName == '' || languageName == undefined) {
            setErrorMsg('Please Enter Language Name.');
            return;
        }
        if (imageName && imageName == '' || imageName == undefined) {
            setErrorMsg('Please Upload Flag Image.');
            return;
        }
        try {
            let json = {
                "name": languageName,
                "image_flag": imageName
            };
            const response = await put(BASE_URL + '/languages/' + languageId, json)
            if (response) {
                notify('Language updated successfully');
                navigate('/language');
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    function handleUpload(url) {
        setImageName(url)
        setShowImagePopUp(false)
        setErrorMsg("")
    }

    return (
        <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
            <div className="flex justify-between items-center h-20">
                <Header category="" title={fromStatus == "create" ? "Create Language" : "Edit Language"} />
            </div>
            <div className="countryFormMainDiv p-10">
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, flexDirection: 'column' }}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
                            <span style={{ fontSize: 20, color: '#000' }}>Name</span>
                            <input type="text" className="InputBoxCss" style={{ width: 450 }} value={languageName} onChange={(e) => { setLanguageName(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Image</span>
                        {imageName && imageName != "" ?
                            <>
                                <img src={imageName} style={{ width: 150, height: 150, borderRadius: 10, objectFit: 'cover' }} alt="" />
                                <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                                    Change Image
                                </div>
                            </>
                            :
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleUpload}
                                width="350"
                            />
                        }
                    </div>


                </div>
                {errorMsg != '' &&
                    <div style={{ textAlign: 'center', color: 'red', fontSize: 16, marginTop: 30, }}>
                        {errorMsg}
                    </div>
                }
                {fromStatus == "create" ?
                    <div className="AddBtn" onClick={() => { CreateLanguageFun() }}>
                        Add
                    </div>
                    :
                    <div className="AddBtn" onClick={() => { editLanguageFun() }}>
                        Edit
                    </div>
                }

            </div>

            {showImagePopUp && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => { setShowImagePopUp(false) }}
                >
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: 10,
                            padding: '15px'
                        }}
                    >
                        <SimpleFileUpload
                            apiKey={SimpleFileUploadAPIKey}
                            onSuccess={handleUpload}
                            width="350"
                        // onDrop={handleOnDrop}
                        />
                    </div>
                </div>
            )}

        </div>


    );
};

export default CreateLanguage;
