
import React, { useEffect, useState } from 'react';
import './Login.css'
import IndieCircleLogo from '../../data/Indie_circle_logo.png'
import BackArrowIcon from '../../data/backArrow.png'
import AuthLoader from '../../data/authLoader.gif'
import { BASE_URL, post } from '../../Services/Calls';

const Login = () => {

  const [showTwoFactor, setShowTwoFactor] = useState(false)
  const [passwordEye, setPasswordEye] = useState(false)
  const [loader, setLoader] = useState(false)
  const [emailErrorMsg, setEmailErrorMsg] = useState("")
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("")
  const [loginData, setLoginData] = useState({})

  // State to store OTP digits
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpLength = otp.length;
  useEffect(() => {
    // Auto-submit the form if OTP is complete
    if (otpLength === otp.length && otp.every((digit) => digit !== '')) {
      twoFactorSubmit();
    }
  }, [otp]);


  // Function to handle changes in the OTP input fields
  const handleChange = (index, value) => {
    // Ensure that the value is a single digit
    if (/^[0-9]$/.test(value)) {
      // Update the OTP array with the new value
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });

      // Move to the next input field
      if (index < otp.length - 1 && value !== '') {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (event) => {

    event.preventDefault();
    const formData = event.currentTarget.elements;

    try {
      if (formData[0].value === '') {
        setEmailErrorMsg("Email can't be empty.")
        return;
      }
      if (formData[0].value !== "") {
        let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!pattern.test(formData[0].value)) {
          setEmailErrorMsg("Invalid email format.");
          return;
        }
      }
      if (formData[1].value === '') {
        setPasswordErrorMsg("Password can't be empty.")
        return;
      }
      if (formData[1].value !== "") {
        if (formData[1].value.length < 6) {
          setPasswordErrorMsg("Password requires 6~64 characters long.");
          return;
        }
      }
      if (
        formData[0].value === "" ||
        formData[1].value === ""
      ) {
        return;
      }

      let tempEmail = formData[0].value
      tempEmail = tempEmail.toLowerCase();
      const data = {
        email: tempEmail,
        password: formData[1].value,
      };
      setLoader(true)
      const response = await post(BASE_URL + '/auth/login', data)
      if (response.status == 200) {
        setLoginData(data);
        setShowTwoFactor(true);
        setLoader(false);
      }
    } catch (ex) {
      setLoader(false);
      setShowTwoFactor(false);
      try {
        setPasswordErrorMsg(ex.response.statusText);
      }
      catch (ex) {
        setPasswordErrorMsg("Invalid Email Or Password");
      }
    }

  };

  const twoFactorSubmit = async (e) => {
    try {
      if (otp[0] == '' || otp[1] == '' || otp[2] == '' || otp[3] == '' || otp[4] == '' || otp[5] == '') {
        alert('invalid input')
        return;
      }
      setLoader(true)
      const response = await post(BASE_URL + '/auth/two-factor', {
        mfa_code: parseInt(otp.join(''), 10),
        email: loginData.email,
      });

      if (response && response.data.token && response.data.token.accessToken) {
        localStorage.setItem('token', response.data.token.accessToken);
        localStorage.setItem('loginData', JSON.stringify(loginData));
        setLoader(false);
        window.location.reload();
      }
      else {
        setLoader(false);
        alert('something went wrong, please try again later.')
      }

    } catch (ex) {
      if (ex && ex.response && ex.response.data && ex.response.data.message) {
        setLoader(false);
        alert(ex.response.data.message)
      }
      else {
        setLoader(false);
        alert(ex)
      }
    }

  }

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('Text').split('');

    // Update OTP values based on pasted data
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      pastedData.forEach((digit, index) => {
        if (index < otpLength) {
          newOtp[index] = digit;
        }
      });

      if (pastedData.length < 6) {
        document.getElementById(`otp-input-${pastedData.length}`).focus();
      }
      else {
        document.getElementById(`otp-input-${pastedData.length - 1}`).focus();
      }
      return newOtp;
    });
  };


  const handleBackspace = (index) => {
    // Update the OTP array with the new value
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = '';
      return newOtp;
    });

    // Move to the next input field
    if (index < otp.length && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <div className='LoginMainContainer'>
      {!showTwoFactor ?
        <div className='LoginInnerContainer'>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
            <img src={IndieCircleLogo} alt="" />
          </div>
          <div style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center', marginBottom: 35 }}>Admin Login</div>
          <form onSubmit={handleSubmit}>
            <div className='loginInputCss'>
              {/* <label htmlFor="username">Username:</label> */}
              <span className="ant-input-prefix">
                <span role="img" aria-label="user" className="anticon anticon-user">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                    </path>
                  </svg>
                </span>
              </span>
              <input
                type="text"
                id="username"
                name="username"
                // value={formData.username}
                // onChange={handleChange}
                placeholder='Email'
                onFocus={() => { setEmailErrorMsg("") }}
              // required
              />
            </div>
            {emailErrorMsg != "" &&
              <div className='inputErrorMsg'>{emailErrorMsg}</div>
            }
            <div className='loginInputCss'>
              {/* <label htmlFor="password">Password:</label> */}
              <span className="ant-input-prefix">
                <span role="img" aria-label="lock" className="anticon anticon-lock">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="lock" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z">
                    </path>
                  </svg>
                </span>
              </span>
              <input
                type={passwordEye ? "text" : "password"}
                id="password"
                name="password"
                placeholder='Password'
                // value={formData.password}
                // onChange={handleChange}
                onFocus={() => { setPasswordErrorMsg("") }}
              // required

              />

              {passwordEye ?
                <span className="ant-input-suffix" onClick={() => { setPasswordEye(!passwordEye) }} style={{ cursor: 'pointer' }}>
                  <span role="img" aria-label="eye" tabindex="-1" className="anticon anticon-eye ant-input-password-icon">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="eye" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z">
                      </path>
                    </svg>
                  </span>
                </span>
                :
                <span className="ant-input-suffix" onClick={() => { setPasswordEye(!passwordEye) }} style={{ cursor: 'pointer' }}>
                  <span role="img" aria-label="eye-invisible" tabindex="-1" className="anticon anticon-eye-invisible ant-input-password-icon">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="eye-invisible" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z">
                      </path>
                      <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z">
                      </path>
                    </svg>
                  </span>
                </span>
              }
            </div>
            {passwordErrorMsg != "" &&
              <div className='inputErrorMsg'>{passwordErrorMsg}</div>
            }

            <div>
              <button type="submit" className='loginBtn'>Login</button>
            </div>
          </form>
        </div>
        :
        <div className='LoginInnerContainer'>
          <div className='backArrowiconDiv' onClick={() => { setShowTwoFactor(!showTwoFactor); setOtp(['', '', '', '', '', '']) }}>
            <img src={BackArrowIcon} alt="" />
            <span>Back</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 25 }}>
            <img src={IndieCircleLogo} alt="" />
          </div>
          <div style={{ fontSize: 22, fontWeight: 'bold', textAlign: 'center', marginBottom: 30 }}>Two-Factor Authentication</div>
          <div>
            <form>
              <div className='two-factor-form'>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    id={`otp-input-${index}`}
                    name={`otp-input-${index}`}
                    autoFocus={index === 0}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onPaste={handlePaste}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        handleBackspace(index);
                      }
                    }}
                  />
                ))}
              </div>
              {otp[0] != '' &&
                <div style={{ marginTop: 20 }}>
                  <button style={{ background: '#fff', color: '#000', border: '1px solid grey' }} className='loginBtn' onClick={() => { setOtp(['', '', '', '', '', '']) }}>Clear</button>
                </div>
              }
            </form>
            <div style={{ marginTop: otp[0] != '' ? 10 : 30 }}>
              <button className='loginBtn' onClick={() => { twoFactorSubmit() }}>Submit</button>
            </div>
            {/* <p>Entered OTP: {otp.join('')}</p> */}

          </div>
        </div>
      }

      {loader &&
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(0,0,0,0.2)' }}>
          <img src={AuthLoader} />
        </div>
      }
    </div>
  );
};

export default Login;
