import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  GridComponent,
  Inject,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './NewMain.css';

import { BASE_URL, deleteCall, get } from "../../Services/Calls";
import { Header } from "../../components";
import BackWard from "../../data/backWard.png";
import CreateAddIcon from "../../data/createAddIcon.png";
import { localExpertGrid } from "../../data/dummy";
import Forward from "../../data/forward.png";
import LeftArrow from "../../data/leftArrow.png";
import RightArrow from "../../data/rightArrow.png";

const LocalExperts = () => {

  const navigate = useNavigate()

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Delete", 'Search'];
  const editing = { allowDeleting: true, allowEditing: true };

  const [localExpertData, setLocalExpertData] = useState([])


  const [selectedRecords, setSelectedRecords] = useState([]) // Selected records to be deleted

  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const editOptions = { allowEditing: false, allowAdding: true, allowDeleting: true };


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Number of records per page

  // Function to handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
    getLocalExpertData(page)
  };



  useEffect(() => {
    getLocalExpertData(currentPage)
  }, [])


  const getLocalExpertData = async (pageValue) => {
    try{
    const response = await get(BASE_URL + `/local-experts?page=${pageValue}&take=10`);
    if (response && response.data && response.data.length > 0) {
      setLocalExpertData(response.data)
      setTotalPages(Math.ceil(response.meta.itemCount / pageSize))
      setTotalItems(response.meta.itemCount)
    }
  }
  catch(ex){
    alert(ex)
  }
  };

  const numbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const toolbarClick = args => {
    switch (args.item.text) {
      case 'Delete':
        args.cancel = true;
        setDeleteConfirm(true)
        break
      default:
        break
    }
  }

  const handleDelete = async () => {

    try {
      for (let i = 0; i < selectedRecords.length; i++) {
        const response = deleteCall(
          await BASE_URL + '/local-experts/' + selectedRecords[i].id
        )
        if (response) {

        }
      }
      setTimeout(() => {
        getLocalExpertData()
        setDeleteConfirm(false)
      }, 200);

    } catch (ex) { 
      alert(ex)
    }
  }



  let grid

  const rowSelected = () => {
    if (grid) {
      const tempSelectedrecords = grid.getSelectedRecords()
      setSelectedRecords(tempSelectedrecords)
    }
  }

  const rowDeselected = args => {
    let tempSelectedRecords = [...selectedRecords]
    for (let i = 0; i < tempSelectedRecords.length; i++) {
      if (args.data.length > 1) {
        for (let j = 0; j < args.data.length; j++) {
          if (tempSelectedRecords[i].id == args.data[j].id) {
            tempSelectedRecords.splice(i, 1)
          }
        }
      } else {
        if (tempSelectedRecords[i].id == args.data.id) {
          tempSelectedRecords.splice(i, 1)
        }
      }
    }
    setSelectedRecords(tempSelectedRecords)
  }

  return (
    <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
      <div className="flex justify-between items-center h-20">
        <Header category="" title="Local Experts" />
        <div style={{ width: 200 }} className="w-100 border flex justify-end items-center mb-8 createBtn" onClick={() => { navigate('/createExpert', { state: { from: 'create', data: {} } }) }}>
          <span>Add New Expert</span>
          <img src={CreateAddIcon} style={{ width: 15, height: 15 }} alt="" />
        </div>
      </div>
      {localExpertData && localExpertData.length > 0 &&
      <GridComponent
        dataSource={localExpertData}
        editSettings={editOptions}
        toolbar={toolbarOptions}
        allowPaging={false}
        allowSorting
        // pageSettings={{ pageCount: 2, pageSize: 1 }}
        toolbarClick={toolbarClick}
        rowSelected={rowSelected}
        rowDeselected={rowDeselected}
        ref={g => (grid = g)}
      >
        <ColumnsDirective>
          {localExpertGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Edit, Toolbar]} />
      </GridComponent>
     }

<div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center',padding: '10px 10px 10px 27px',border: '1px solid #e0e0e0'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
        <div onClick={() => { if (currentPage > 1) {onPageChange(1)} }} style={{ marginRight: 21 }}>
            <img src={BackWard} style={{ width: 11, height: 11, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (currentPage > 1) { onPageChange(currentPage - 1) } }} style={{ marginRight: 14 }}>
            <img src={LeftArrow} style={{ width: 9, height: 9, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
            {currentPage > 2 &&
              <div className="pagingNumber" onClick={() => {onPageChange(currentPage - 2)}} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
            {numbers.map((number) => (
              <>
                {((number == currentPage || number == currentPage - 1 || number == currentPage + 1) || (currentPage == 1 && number == 3) || (currentPage == totalPages && number == totalPages - 2)) &&
                  <div key={number} className={currentPage == number ? "pagingNumberSelected" : "pagingNumber"} onClick={() => {onPageChange(number)}}>{number}</div>
                }
              </>
            ))}
            {currentPage + 1 < totalPages &&
              <div className="pagingNumber" onClick={() => {onPageChange(currentPage + 2) }} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
          </div>
          <div onClick={() => { if (totalPages != currentPage) { onPageChange(currentPage + 1) } }} style={{ marginLeft: 13.5 }}>
            <img src={RightArrow} style={{ width: 10, height: 10, opacity: totalPages != currentPage ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (totalPages != currentPage) { onPageChange(totalPages) } }} style={{ marginLeft: 19 }}>
            <img src={Forward} style={{ width: 12, height: 12, opacity: totalPages != currentPage ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
        </div>
        <span style={{ margin: '0 6.5px', fontSize: 13 }}>{`${currentPage} of ${totalPages} Pages (${totalItems} items)`}</span>
      </div>

     {deleteConfirm && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: 400,
              height: 200,
              background: '#fff',
              borderRadius: 10,
              padding: '15px 20px'
            }}
          >
            <div
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                color: '#000',
                marginBottom: 15
              }}
            >
              Delete Alert
            </div>
            <div style={{ fontSize: 18, color: '#000', marginBottom: 10 }}>
              {selectedRecords.length > 0 ? "Are you sure you want to delete selected rows." : "Please select at-least one row."}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 20
              }}
            >
              <div
                className='confirmOkBtn'
                onClick={() => {
                  getLocalExpertData()
                  setDeleteConfirm(false)
                }}
              >
                {selectedRecords.length > 0 ? "Cancle" : "Ok"}
              </div>
              {selectedRecords.length > 0 &&
                <div
                  className='confirmOkBtn'
                  onClick={() => {
                    handleDelete()
                  }}
                >
                  Yes
                </div>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocalExperts;
