import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleFileUpload from 'react-simple-file-upload'
import { BASE_URL, get, post, put } from '../../Services/Calls'
import { notify, SimpleFileUploadAPIKey } from '../../Services/Utils'
import { Header } from '../../components'
import DropDownArrow from '../../data/rightArrow.png'
import './NewMain.css'

const CreateExpert = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [expertName, setExpertName] = useState('')
  const [description, setDescription] = useState('')
  const [imageName, setImageName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [active, setActive] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [fromStatus, setFromStatus] = useState('')
  const [expertId, setExpertId] = useState('')
  const [showImagePopUp, setShowImagePopUp] = useState(false);
  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [countryData, setCountryData] = useState([])
  const [country, setCountry] = useState({})


  useEffect(() => {
    getCountries()
  }, [])

  const getCountries = async () => {
    try {
      const response = await get(BASE_URL + '/countries?order=ASC')
      if (response && response.length > 0) {
        setCountryData(response)
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  useEffect(() => {
    if (location && location.state && location.state.from) {
      setFromStatus(location.state.from);
      if (location.state.data && location.state.data.id) {
        setExpertName(location.state.data.name)
        setDescription(location.state.data.description)
        setImageName(location.state.data.imageURL)
        setEmail(location.state.data.email)
        setPhone(location.state.data.phone)
        setCountry(location.state.data.country)
        setActive(location.state.data.active)
        setExpertId(location.state.data.id)
      }
    }
    window.scrollTo(0, 0);
  }, [])

  async function createExpertFun() {
    if (expertName == '' || expertName == undefined) {
      setErrorMsg('Please Enter Expert Name.')
      return
    }
    if (imageName == "" || imageName == undefined) {
      setErrorMsg('Please Upload Expert Image.')
      return;
    }
    if (email == '' || email == undefined) {
      setErrorMsg('Please Enter Expert Email.')
      return
    }
    if (email != '') {
      let pattern =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (!pattern.test(email)) {
        setErrorMsg('Invalid email format.')
        return
      }
    }

    if (phone == '' || phone == undefined) {
      setErrorMsg('Please Enter Expert Phone Number.')
      return
    }
    if (country && country.id) {

    }
    else {
      setErrorMsg('Please Select Country.')
      return
    }

    if (description == '' || description == undefined) {
      setErrorMsg('Please Enter Expert Description.')
      return
    }

    try {
      let json = {
        country_id: country.id,
        name: expertName,
        active: active,
        imageURL: imageName,
        email: email,
        phone: phone,
        description: description
      }
      const response = await post(BASE_URL + '/local-experts', json);
      if (response) {
        notify('Local-expert created successfully');
        setExpertName('');
        setDescription('');
        setCountry({});
        setActive(true);
        setImageName("");
        setEmail("");
        setPhone("");
        setDescription("");
        navigate('/localExperts');
      }
    } catch (ex) {
      alert(ex)
    }
  }

  async function editExpertFun() {
    if (expertName == '' || expertName == undefined) {
      setErrorMsg('Please Enter Expert Name.');
      return
    }
    if (imageName && imageName == '' || imageName == undefined) {
      setErrorMsg('Please Upload Country Image.');
      return;
    }

    if (email == '' || email == undefined) {
      setErrorMsg('Please Enter Expert Email.');
      return
    }
    if (email != '') {
      let pattern =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (!pattern.test(email)) {
        setErrorMsg('Invalid email format.')
        return
      }
    }

    if (phone == '' || phone == undefined) {
      setErrorMsg('Please Enter Expert Phone Number.');
      return;
    }
    if (country && country.id) {

    }
    else {
      setErrorMsg('Please Select Country.');
      return
    }

    if (description == '' || description == undefined) {
      setErrorMsg('Please Enter Expert Description.');
      return
    }

    try {
      const json = {
        country_id: country.id,
        name: expertName,
        active: active,
        imageURL: imageName,
        email: email,
        phone: phone,
        description: description
      };
      const response = await put(BASE_URL + '/local-experts/' + expertId, json);
      if (response) {
        notify('Local-expert updated successfully');
        setExpertName('');
        setDescription('');
        setCountry({});
        setActive(true);
        setImageName("");
        setEmail("");
        setPhone("");
        setDescription("");
        setExpertId("");
        navigate('/localExperts');
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  function handleUpload(url) {
    setImageName(url)
    setShowImagePopUp(false)
    setErrorMsg("")
  }

  return (
    <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
      <div className='flex justify-between items-center h-20'>
        <Header
          category=''
          title={fromStatus == 'create' ? 'Add New Expert' : 'Edit Expert'}
        />
      </div>
      <div className='countryFormMainDiv p-10'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Name</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={expertName}
                onChange={e => {
                  setExpertName(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%',
                marginTop: 25
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Email</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '40%',
                marginTop: 25
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Phone</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={phone}
                onChange={e => {
                  setPhone(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
            <span style={{ fontSize: 20, color: '#000' }}>Image</span>
            {imageName && imageName != "" ?
              <>
                <img src={imageName} style={{ width: 150, height: 150, borderRadius: 10 }} alt="" />
                <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                  Change Image
                </div>
              </>
              :
              <SimpleFileUpload
                apiKey={SimpleFileUploadAPIKey}
                onSuccess={handleUpload}
                width="350"
              />
            }
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: 25
          }}
        >
          <div
            style={{
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'flex-start',
              // flexDirection: 'column',
              // gap: 10,
              width: '60%'
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>Country</span>
            {/* <input
              type='text'
              className='InputBoxCss'
              style={{ width: 350 }}
              value={countryId}
              onChange={e => {
                setCountryId(e.target.value)
              }}
              onFocus={() => {
                setErrorMsg('')
              }}
            /> */}

            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
              {country && country.id ?
                <span style={{ color: '#000' }}>{country.name}</span>
                :
                <span style={{ color: 'gray' }}>Select Country</span>
              }
              <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
            </div>

            {showCountrySelect &&
              <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                <div className="dropDownDiv">
                  {countryData && countryData.length > 0 && countryData.map((item, index) => (
                    <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                      {item.name}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            }

          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: 10,
              width: '40%'
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>Active</span>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 40
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 15
                }}
              >
                <div
                  style={{
                    border: '2px solid blue',
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    padding: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setActive(true)
                  }}
                >
                  {active && (
                    <div
                      style={{
                        background: 'blue',
                        width: '100%',
                        height: '100%',
                        borderRadius: 50
                      }}
                    ></div>
                  )}
                </div>
                <div>True</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 15
                }}
              >
                <div
                  style={{
                    border: '2px solid blue',
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    padding: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setActive(false)
                  }}
                >
                  {!active && (
                    <div
                      style={{
                        background: 'blue',
                        width: '100%',
                        height: '100%',
                        borderRadius: 50
                      }}
                    ></div>
                  )}
                </div>
                <div>False</div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 10,
            marginTop: 40
          }}
        >
          <span style={{ fontSize: 20, color: '#000' }}>Description</span>
          <textarea
            aria-invalid='false'
            id='txtDescription'
            rows='5'
            class='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss'
            style={{ width: 600 }}
            value={description}
            onChange={e => {
              setDescription(e.target.value)
            }}
            onFocus={() => setErrorMsg('')}
          />
        </div>

        {errorMsg != '' && (
          <div
            style={{
              textAlign: 'center',
              color: 'red',
              fontSize: 16,
              marginTop: 30
            }}
          >
            {errorMsg}
          </div>
        )}
        {fromStatus == 'create' ? (
          <div
            className='AddBtn'
            onClick={() => {
              createExpertFun()
            }}
          >
            Add
          </div>
        ) : (
          <div
            className='AddBtn'
            onClick={() => {
              editExpertFun()
            }}
          >
            Edit
          </div>
        )}
      </div>

      {showImagePopUp && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => { setShowImagePopUp(false) }}
        >
          <div
            style={{
              background: '#fff',
              borderRadius: 10,
              padding: '15px'
            }}
          >
            <SimpleFileUpload
              apiKey={SimpleFileUploadAPIKey}
              onSuccess={handleUpload}
              width="350"
            // onDrop={handleOnDrop}
            />
          </div>
        </div>
      )}

    </div>
  )
}

export default CreateExpert
