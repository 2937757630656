import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom'

import { Button } from ".";
import { userProfileData } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import avatar from "../data/avatar.jpg";
import { ClickAwayListener } from "@mui/base";

const UserProfile = () => {
  const navigate = useNavigate()
  
  const {
    setIsClicked,
    initialState
  } = useStateContext();

  const { currentColor } = useStateContext();

  const [loginData, setLoginData] = useState([]);

  useEffect(() => {
    const tempLoginData = JSON.parse(localStorage.getItem('loginData'));
  if (tempLoginData) {
    setLoginData(tempLoginData);
  }
  }, [])

  return (
    <ClickAwayListener onClickAway={() => setIsClicked(initialState)}>
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-14 w-14"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {loginData.email}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            Administrator
          </p>
        </div>
      </div>
      <div className="mt-5" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <button className="logOutBtn"  onClick={()=>{localStorage.clear();navigate('/');window.location.reload();}}>
          Log Out
        </button>
      </div>
    </div>
    </ClickAwayListener>
  );
};

export default UserProfile;
