import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL, get, post, put } from '../../Services/Calls'
import { notify } from '../../Services/Utils'
import { Header } from '../../components'
import DropDownArrow from '../../data/rightArrow.png'
import './NewMain.css'

const CreateCarRent = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [carName, setcarName] = useState('')
  const [lowSeasonPrice, setLowSeasonPrice] = useState(0)
  const [highSeasonPrice, setHighSeasonPrice] = useState(0)

  const [carLevel, setCarLevel] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [fromStatus, setFromStatus] = useState('')

  const [carRentId, setCarRentId] = useState('')

  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [countryData, setCountryData] = useState([])
  const [country, setCountry] = useState({})


  useEffect(() => {
    getCountries()
  }, [])

  const getCountries = async () => {
    try {
      const response = await get(BASE_URL + '/countries?order=ASC')

      if (response && response.length > 0) {
        setCountryData(response)
      }
    }
    catch (ex) {
      alert(ex)
    }
  }


  useEffect(() => {
    if (location && location.state && location.state.from) {
      setFromStatus(location.state.from)
      if (location.state.data && location.state.data.id) {
        /*  setExpertName(location.state.data.name)
         setDescription(location.state.data.description)
         setImageName(location.state.data.imageURL)
         setEmail(location.state.data.email)
         setPhone(location.state.data.phone)
         setCountry(location.state.data.country)
         setActive(location.state.data.active)
         setExpertId(location.state.data.id) */

        setcarName(location.state.data.car_name)
        setCountry(location.state.data.country)
        setCarLevel(location.state.data.car_level_basic)
        setLowSeasonPrice(location.state.data.low_season_price)
        setHighSeasonPrice(location.state.data.high_season_price)
        setCarRentId(location.state.data.id)
      }
    }
    window.scrollTo(0, 0)
  }, [])

  async function createExpertFun() {
    if (carName == '' || carName == undefined) {
      setErrorMsg('Please Enter Car Name.')
      return
    }

    if (country && country.id) {

    }
    else {
      setErrorMsg('Please Select Country.')
      return
    }

    if (lowSeasonPrice == '' || lowSeasonPrice == undefined) {
      setErrorMsg('Please Enter Low Season Price.')
      return
    }
    if (highSeasonPrice == '' || highSeasonPrice == undefined) {
      setErrorMsg('Please Enter High Season Price.')
      return
    }

    try {
      let json = {
        country: country.id,
        car_name: carName,
        car_level_basic: carLevel,
        low_season_price: parseInt(lowSeasonPrice),
        high_season_price: parseInt(highSeasonPrice),
      }
      const response = await post(BASE_URL + '/car-rental-prices-list', json)

      if (response) {
        notify('Car Rent created successfully');
        setcarName('');
        setCountry({});
        setCarLevel(true);
        setLowSeasonPrice(0);
        setHighSeasonPrice(0);
        navigate('/CarRentalPrices');
      }
    } catch (ex) {
      alert(ex)
    }
  }

  async function editExpertFun() {
    if (carName == '' || carName == undefined) {
      setErrorMsg('Please Enter Car Name.')
      return
    }

    if (country && country.id) {

    }
    else {

      setErrorMsg('Please Select Country.')
      return
    }

    if (lowSeasonPrice == '' || lowSeasonPrice == undefined) {
      setErrorMsg('Please Enter Low Season Price.')
      return
    }
    if (highSeasonPrice == '' || highSeasonPrice == undefined) {
      setErrorMsg('Please Enter High Season Price.')
      return
    }

    try {
      let json = {
        country: country.id,
        car_name: carName,
        car_level_basic: carLevel,
        low_season_price: parseInt(lowSeasonPrice),
        high_season_price: parseInt(highSeasonPrice),
      }

      const response = await put(BASE_URL + '/car-rental-prices-list/' + carRentId, json)

      if (response) {
        notify('Car Rent updated successfully')
        setcarName('')
        setCountry({})
        setCarLevel(true)
        setLowSeasonPrice(0)
        setHighSeasonPrice(0)
        navigate('/CarRentalPrices')
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  return (
    <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
      <div className='flex justify-between items-center h-20'>
        <Header
          category=''
          title={fromStatus == 'create' ? 'Add New Car Rent' : 'Edit Car Rent'}
        />
      </div>
      <div className='countryFormMainDiv p-10'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Car Name</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={carName}
                onChange={e => {
                  setcarName(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>


        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: 25
          }}
        >
          <div
            style={{
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'flex-start',
              // flexDirection: 'column',
              // gap: 10,
              width: '60%'
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>Country</span>

            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
              {country && country.id ?
                <span style={{ color: '#000' }}>{country.name}</span>
                :
                <span style={{ color: 'gray' }}>Select Country</span>
              }
              <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
            </div>

            {showCountrySelect &&
              <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                <div className="dropDownDiv">
                  {countryData && countryData.length > 0 && countryData.map((item, index) => (
                    <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                      {item.name}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            }

          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: 10,
              width: '40%'
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>Car Level</span>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 40
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 15
                }}
              >
                <div
                  style={{
                    border: '2px solid blue',
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    padding: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setCarLevel(true)
                  }}
                >
                  {carLevel && (
                    <div
                      style={{
                        background: 'blue',
                        width: '100%',
                        height: '100%',
                        borderRadius: 50
                      }}
                    ></div>
                  )}
                </div>
                <div>Basic</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 15
                }}
              >
                <div
                  style={{
                    border: '2px solid blue',
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    padding: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setCarLevel(false)
                  }}
                >
                  {!carLevel && (
                    <div
                      style={{
                        background: 'blue',
                        width: '100%',
                        height: '100%',
                        borderRadius: 50
                      }}
                    ></div>
                  )}
                </div>
                <div>Upgraded</div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Low season price</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={lowSeasonPrice}
                onChange={e => {
                  setLowSeasonPrice(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>
          <div style={{ width: '40%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>High season price</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={highSeasonPrice}
                onChange={e => {
                  setHighSeasonPrice(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>


        </div>















        {errorMsg != '' && (
          <div
            style={{
              textAlign: 'center',
              color: 'red',
              fontSize: 16,
              marginTop: 30
            }}
          >
            {errorMsg}
          </div>
        )}
        {fromStatus == 'create' ? (
          <div
            className='AddBtn'
            onClick={() => {
              createExpertFun()
            }}
          >
            Add
          </div>
        ) : (
          <div
            className='AddBtn'
            onClick={() => {
              editExpertFun()
            }}
          >
            Edit
          </div>
        )}
      </div>



    </div>
  )
}

export default CreateCarRent
