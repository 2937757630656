import { toast } from 'react-toastify';

export const notify = (msg) => {
    toast.success(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
};

export const isValidDate = date => {
    return date instanceof Date && !isNaN(date);
};

export const SimpleFileUploadAPIKey = "7c6c0d45732d0c9f2e20cd78043c65fd";

export const mapboxglAccessToken = 'pk.eyJ1IjoidXJpYWJyaWRnaWZ5IiwiYSI6ImNrb3IybjRtYjEwd2wydnBpdGxvNWVoYzIifQ.jUaSkJhni2GW103YouzXOQ';