import axios from "axios";


export const BASE_URL = "https://indie4x4-backend-9bff9eabafde.herokuapp.com/api/v1";

const _config = {};

export async function put(url, data) {
  if (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined
  ) {
    axios.defaults.headers.common[
      "authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }
  try {
    const response = await axios.put(url, data, _config);
    return response;
  }
  catch (error) {
    if (error.response.data.message[0] && error.response.data.message[0].property) {
      throw new Error(`Problem with field: ${error.response.data.message[0].property}, value: ${error.response.data.message[0].value}`);
    }
    throw new Error("API error");
  }
}

export async function patch(url, data) {
  if (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined
  ) {
    axios.defaults.headers.common[
      "authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }
  try {
    const response = await axios.patch(url, data, _config);
    return response;
  }
  catch (error) {
    if (error.response.data.message[0] && error.response.data.message[0].property) {
      throw new Error(`Problem with field: ${error.response.data.message[0].property}, value: ${error.response.data.message[0].value}`);
    }
    throw new Error("API error");
  }
}

export async function post(url, data) {
  if (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined
  ) {
    axios.defaults.headers.common[
      "authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }
  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    if (error.response.data.message[0] && error.response.data.message[0].property) {
      throw new Error(`Problem with field: ${error.response.data.message[0].property}, value: ${error.response.data.message[0].value}`);
    }
    throw new Error("API error");
  }
}

export async function get(url, config = {}) {
  if (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined
  ) {
    axios.defaults.headers.common[
      "authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }
  config = {
    ..._config,
    ...config,
  };
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    throw new Error("API error");
  }
}

export async function deleteCall(url, config = {}) {
  if (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined
  ) {
    axios.defaults.headers.common[
      "authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }
  config = {
    ..._config,
    ...config,
  };
  try {
    const response = await axios.delete(url, config);
    return response.data;
  } catch (error) {
    throw new Error("API error");
  }
}
