import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL, patch } from '../../Services/Calls';
import { Header } from '../../components';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import DropDownArrow from '../../data/rightArrow.png';
import './NewMain.css'
import { notify } from '../../Services/Utils';

const EditLead = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [leadId, setLeadId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [destination, setDestination] = useState('');
  const [status, setStatus] = useState({});
  const [phone, setPhone] = useState('');
  const [showStatusSelect, setShowStatusSelect] = useState(false);
  const [statusArray, setStatusArray] = useState([
    {
      id: 1,
      name: 'Pending',
      type: 1,
    },
    {
      id: 2,
      name: 'In Progress',
      type: 2,
    },
    {
      id: 3,
      name: 'Completed',
      type: 3,
    },
  ]);

  useEffect(() => {
    if (location && location.state && location.state.from) {
      if (location.state.data && location.state.data.id) {
        setLeadId(location.state.data.id);
        setName(location.state.data.name);
        setEmail(location.state.data.email);
        setDescription(location.state.data.description);
        setDestination(location.state.data.destination);
        setStatus({
          name: location.state.data.status
        });
        setPhone(location.state.data.phone);
      }
    }
    window.scrollTo(0, 0)
  }, []);

  async function updateLead() {
    try {
      let json = {
        status: status.name,
      };
      const response = await patch(BASE_URL + '/leads/' + leadId, json);
      if (response) {
        notify('Lead Updated Successfully');
        navigate('/leads');
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  return (
    <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
      <div className='flex justify-between items-center h-20'>
        <Header
          category=''
          title={'Update Lead'}
        />
      </div>
      <div className='countryFormMainDiv p-10'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Name</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 250 }}
                value={name}
                disabled='true'
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Email</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 250 }}
                value={email}
                disabled='true'
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Description</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 250 }}
                value={description}
                disabled='true'
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px',
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Destination</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 250 }}
                value={destination}
                disabled='true'
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Phone</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 250 }}
                value={phone}
                disabled='true'
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <div>
                <span style={{ fontSize: 20, color: '#000' }}>
                  Status
                </span>
                <div className='InputBoxCss' style={{ width: 200, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px' }} onClick={() => { setShowStatusSelect(!showStatusSelect) }}>
                  <span style={{ color: '#000' }}>{status.name}</span>
                  <img src={DropDownArrow} style={{ width: 15, height: 15, transform: 'rotate(90deg)' }} />
                </div>
                {showStatusSelect &&
                  <ClickAwayListener onClickAway={() => { setShowStatusSelect(false); }}>
                    <div className="dropDownDiv" style={{ marginTop: 0, width: 200 }}>
                      {statusArray && statusArray.length > 0 && statusArray.map((item, index) => (
                        <div className="countryDropDownItemDiv" onClick={() => { setStatus(item); setShowStatusSelect(false); }}>
                          {item.name}
                        </div>
                      ))}
                    </div>
                  </ClickAwayListener>
                }
              </div>
            </div>
          </div>
        </div>

        <div
          className='AddBtn'
          onClick={() => {
            updateLead()
          }}
        >
          Update
        </div>
      </div>
    </div>
  )
}

export default EditLead